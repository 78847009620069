<template>
  <div class="commonListPage">
    <div class="list-opt">
      <el-button type="primary" icon="el-icon-plus" size="medium" plain @click="create(0)">新增活动</el-button>
      <div style="display: flex;align-items: center;flex-wrap:wrap">
        <el-date-picker
          style="margin-right: 10px;width: 360px"
          v-model="dateTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          placeholder="选择时间范围">
        </el-date-picker>
        <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select" style="width: 400px">
          <el-select v-model="searchTxtType" slot="prepend" placeholder="请选择" clearable>
            <el-option label="活动名称" value="activityName"></el-option>
            <el-option label="商家名称" value="masterName"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
        </el-input>
      </div>
    </div>
    <div class="info-list">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="8" :md="4" :lg="4" :xl="4" v-for="(item, i) in initInfoList" :key="i">
          <div class="info-content">
            <span>{{item.name}}</span>
            <span>{{attachment[item.key]}}</span>
          </div>
        </el-col>
      </el-row>
      <div class="info-list-action" @click="()=>{openFlag = !openFlag}">
        <span>{{ openFlag ? '收起' : '展开'}}</span>
        <i :class="openFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </div>
    </div>
    <div ref="tableWrap" style="height: calc(100% - 156px)" v-loading="listLoading">
      <el-table
        :data="tableData"
        border
        size="small"
        style="width: 100%;"
        :height="tableHeight"
        :row-class-name="tableRowClassName">
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :fixed="col.fixed || false"
          :prop="col.prop"
          :label="col.label"
          :width="col.width">
          <template slot-scope="scope">
            <div v-if="col.prop == 'name'" style="display:flex;align-items: center;justify-content: center;">
              <template v-if="scope.row.masterLogo && scope.row.masterLogo != ''">
                <el-image
                  style="width: 60px; height: 60px;margin-right:10px"
                  :src="JSON.parse(scope.row.masterLogo).logo"
                  :preview-src-list="[JSON.parse(scope.row.masterLogo).logo]">
                </el-image>
              </template>
              <span>{{scope.row.masterName}}</span>
            </div>
            <template v-else-if="col.prop == 'online'">
              <el-switch
                v-model="scope.row.online"
                :active-value="1"
                :inactive-value="0"
                @change="activityonline($event, scope.row)">
              </el-switch>
            </template>
            <template v-else-if="col.prop == 'discountType'">
              {{discountType[scope.row.discountType]}}
            </template>
            <template v-else-if="col.prop == 'action'">
              <el-button type="primary" icon="el-icon-edit-outline" plain size="small" @click="create(1, scope.row)"></el-button>
              <el-button type="primary" icon="el-icon-view" plain size="small" @click="openSJFile(scope.row)"></el-button>
            </template>
            <span v-else>
              {{  scope.row[col.key] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-drawer
      :title="drawerTitle"
      size="80%"
      :visible.sync="drawerShow">
      <div class="drawer-content">
        <div class="drawer-content-main">
          <createPage ref="forms" @cancelForm="cancelForm" @searchList="searchList"></createPage>
        </div>
        <div class="drawer-bottom">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button v-if="drawerTitle != '查看活动'" type="primary" @click="submitForm" :loading="submitloading">{{ submitloading ? '提交中 ...' : '确定' }}</el-button>
        </div>
      </div>
    </el-drawer>
    <el-drawer
    size="80%"
    :title="'活动排名'"
    :visible.sync="fileShow">
    <div class="drawer-content">
      <div class="drawer-content-main">
        <rankPage ref="fileFrom" @cancelForm="cancelFileFrom"></rankPage>
      </div>
      <div class="drawer-bottom">
        <el-button @click="cancelFileFrom">关闭</el-button>
      </div>
    </div>
  </el-drawer>
    <el-dialog title="修改商家提现费率" :visible.sync="showTXflag" width="500px">
      <el-form :model="txData" label-position="left" label-width="100px">
        <el-form-item label="提现费率">
          <el-input v-model="txData.rate">
            <span slot="suffix" style="padding-right: 10px">%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="开发服务费">
          <el-input v-model="txData.devChagre">
            <span slot="suffix" style="padding-right: 10px">元</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTXflag = false">取 消</el-button>
        <el-button type="primary" @click="submitTX">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="批注" :visible.sync="showPZflag" width="600px">
      <el-input type="textarea" v-model="pzContent" placeholder="请填写批注" rows="5"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPZflag = false">取 消</el-button>
        <el-button type="primary" @click="submitPZ">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="员工注册二维码" :visible.sync="showQRcodeflag" width="300px">
      <div style="text-align: center">
        <el-image
          style="width: 200px; height: 200px"
          :src="require('../../assets/logo.png')">
        </el-image>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import createPage from './create.vue'
import rankPage from './file.vue'
export default {
  name: 'shangjiaList',
  props: {
  },
  components: {
    createPage,
    rankPage
  },
  data() {
    return {
      searchTxt: '',
      searchTxtType: 'activityName',
      dateTime: [],
      infoList: [{
        name: '总订单',
        key: 'sumordernum',
        val: ''
      },{
        name: '总金额',
        key: 'sumtotalmoney',
        val: ''
      },{
        name: '总退款',
        key: 'sumrefundmoney',
        val: ''
      },{
        name: '待核销金额',
        key: 'sumunverifytotalmoney',
        val: ''
      },{
        name: '已核销金额',
        key: 'sumverifytotalmoney',
        val: ''
      },{
        name: '订单总金额',
        key: 'orderMoney',
        val: ''
      },{
        name: '拆红包奖励',
        key: 'sumHongbaoReward',
        val: ''
      },{
        name: '首单红包奖励',
        key: 'sumFirstOrderReward',
        val: ''
      },{
        name: '推荐红包奖励',
        key: 'sumInviteReward',
        val: ''
      },{
        name: '已提现',
        key: 'withdrawSuccessMoney',
        val: ''
      },{
        name: '待审核',
        key: 'withdrawProcessingMoney',
        val: ''
      },{
        name: '待提现',
        key: 'sumAmount',
        val: ''
      }],
      initInfoList: [],
      openFlag: false,
      tableData: [],
      column: [{
        label: '活动编号',
        prop: 'activityCode',
        key: 'activityCode',
        width: 80,
      },{
        label: '商家名称',
        prop: 'name',
        key: 'name',
        width: 200,
      },{
        label: '门店名称',
        prop: 'shopNames',
        key: 'shopNames',
        width: 120,
      },{
        label: '活动名称',
        prop: 'activityName',
        key: 'activityName',
        width: 120,
      },{
        label: '活动上下架',
        prop: 'online',
        key: 'online',
        width: 120,
      },{
        label: '折扣类型',
        prop: 'discountType',
        key: 'discountType',
        width: 120,
      },{
        label: '折扣金额',
        prop: 'discountValue',
        key: 'discountValue',
        width: 120,
      },{
        label: '每人限购数量',
        prop: 'everyoneNumber',
        key: 'everyoneNumber',
        width: 120,
      },{
        label: '开始时间',
        prop: 'startDate',
        key: 'startDate',
        width: 180,
      },{
        label: '过期时间',
        prop: 'expireDate',
        key: 'expireDate',
        width: 180,
      },{
        label: '市场价',
        prop: 'marketPrice',
        key: 'marketPrice',
        width: 120,
      },{
        label: '交易手续费',
        prop: 'platCmsn',
        key: 'platCmsn',
        width: 150,
      },{
        label: '已核销金额',
        prop: 'verifyTotalMoney',
        key: 'verifyTotalMoney',
        width: 130,
      },{
        label: '待核销金额',
        prop: 'unVerifyTotalMoney',
        key: 'unVerifyTotalMoney',
        width: 130,
      },{
        label: '已退款',
        prop: 'refundMoney',
        key: 'refundMoney',
        width: 120,
      },{
        label: '总金额',
        prop: 'totalMoney',
        key: 'totalMoney',
        width: 120,
      },{
        label: '订单总金额',
        prop: 'orderMoney',
        key: 'orderMoney',
        width: 120,
      },{
        label: '拆红包奖励',
        prop: 'sumHongbaoReward',
        key: 'sumHongbaoReward',
        width: 120,
      },{
        label: '首单红包奖励',
        prop: 'sumFirstOrderReward',
        key: 'sumFirstOrderReward',
        width: 130,
      },{
        label: '推荐红包奖励',
        prop: 'sumInviteReward',
        key: 'sumInviteReward',
        width: 130,
      },{
        label: '已提现',
        prop: 'withdrawSuccessMoney',
        key: 'withdrawSuccessMoney',
        width: 120,
      },{
        label: '待提现',
        prop: 'sumAmount',
        key: 'sumAmount',
        width: 120,
      },{
        label: '待审核',
        prop: 'withdrawProcessingMoney',
        key: 'withdrawProcessingMoney',
        width: 120,
      },{
        label: '排序',
        prop: 'sort',
        key: 'sort',
        width: 120,
      },{
        label: '操作',
        prop: 'action',
        key: 'action',
        width: 120,
        fixed: 'right'
      }],
      params: {
        currPage: 1,
        pageSize: 10
      },
      totalCount: 0,
      attachment: {},
      listLoading: true,
      tableHeight: 400,
      drawerTitle: '新增商家',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {}, //商家提现对象
      showPZflag: false, //批注弹窗显示
      pzContent: '',
      showQRcodeflag: false, //员工二维码弹窗
      fileShow: false, //商家资料弹窗
      discountType: {
      // discount：折扣，fixedprice:优惠价
        'discount': '折扣',
        'fixedprice': '优惠价'
      }
    };
  },
  mounted() {
    this.setTableHeight()
    this.searchList()
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    handleResize() {
      console.log('窗口变')
      this.setTableHeight()
    },
    searchList() {
      this.params = {}
      this.params.currPage = 1
      this.params.pageSize = 10
      this.getData()
    },
    getData() {
      this.params[this.searchTxtType] = this.searchTxt
      this.dateTime = this.dateTime || []
      this.params.timeStart = this.dateTime[0] || ''
      this.params.timeEnd = this.dateTime[1] || ''
      this.listLoading = true
      let url = '/back/activity/seckilllist'
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.list || []
          this.totalCount = data.totalCount
          this.attachment = data.attachment
        }
      }).catch(()=>{ this.listLoading = false })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(page) {
      this.params.currPage = page
      this.getData()
    },
    setTableHeight() {
      this.$nextTick(() => {
        if (this.smFlag == 1) {
          console.log('小小小')
          this.column[this.column.length -1].fixed = false
        }
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    },
    create(type, row) {
      this.drawerShow = true
      if (type == 0) {
        this.drawerTitle = '新增活动'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData()
        }, 0)
      }
      if (type == 1) {
        this.drawerTitle = '编辑活动'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData(row)
        }, 0)
      }
      if (type == 2) {
        this.drawerTitle = '查看活动'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData(row)
          this.$refs.forms.setDisable()
        }, 0)
      }
    },
    openSJFile(row) {
      //  打开活动排名
      this.fileShow = true
      setTimeout(() => {
        this.$refs.fileFrom.initData(row)
      }, 0)
    },
    cancelFileFrom() {
      this.fileShow = false
    },
    submitFileForm() {
      this.$refs.fileFrom.submitForm()
    },
    submitForm() {
      this.$refs.forms.submitForm()
    },
    cancelForm() {
      this.$refs.forms.resetForm()
      this.drawerShow = false
    },
    showTX() {
      this.showTXflag = true
    },
    submitTX() {
      this.showTXflag = false
    },
    showPZ() {
      this.showPZflag = true
    },
    submitPZ() {
      this.showPZflag = false
    },
    BDswitch(row) {
      if (row.bindingFlag == 0) {
        // 需要绑定
        this.$confirm('是否需要绑定该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 1
        }).catch(() => {
        });
      }
      if (row.bindingFlag == 1) {
        // 需要解绑
        this.$confirm('是否需要解绑该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 0
        }).catch(() => {
        });
      }
    },
    showQRcode(row) {
      console.log(row)
      this.showQRcodeflag = true
    },
    deleteRow(row) {
      this.$confirm('是否删除该商家?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
      }).catch(() => {
      });
    },
    activityonline(e, row) {
      let query = this.$querystring.stringify({
        activityCode: row.activityCode,
        online: e
      })
      this.$axios.post(`/back/activity/seckill/updateActivityOnline?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
        } else {
          // 没有成功，把状态还原
          row.online = Number(row.online) === 0 ? '1' : '0'
        }
      })
    }
  },
  watch: {
    openFlag: {
      handler: function (val) {
        if (val) {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList))
        } else {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList.slice(0, 6)))
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 120px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
</style>
