<template>
  <el-container style="height: 100vh;">
    <el-aside :width="menuWidth+'px'" style="background-color: #304156;text-align:center;">
      <el-image :style="menuImgStyle" :src="require('../assets/logo.jpg')"></el-image>
      <el-menu class="my-menu" 
        :default-active="menuIndex"
        background-color="#304156"
        text-color="#fff"
        active-text-color="#304156">
        <el-menu-item class="my-menu-item" v-for="(menus, i) in initmenusList" :key="i" :index="String(i)" @click="link(menus, i)">
          <i :class="menus.icon"></i>
          <span class="my-menu-item-title" slot="title" v-if="!foldFlag">{{menus.name}}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="font-size: 13px">
        <div class="my-header">
          <div class="menu-breadcrumb">
            <i v-if="foldFlag" class="el-icon-s-unfold" style="font-size:20px" @click="foldchange"></i>
            <i v-else class="el-icon-s-fold" style="font-size:20px" @click="foldchange"></i>
            <!-- <i class="el-icon-menu" style="font-size:18px"></i> -->
            <span>{{$route.name}}</span>
          </div>
          <div style="float:right;display:flex;">
            <el-dropdown>
              <el-image style="width: 40px;height:40px;margin-right: 8px;top: 12px;" :src="require('../assets/logo2.jpg')"></el-image>
              <!-- <i class="el-icon-user" style="margin-right: 8px;font-size: 15px"></i> -->
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="showPwd">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span>{{userInfo.username}}</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view/>
        <el-dialog title="修改密码" :append-to-body="true" :visible.sync="pwdshow" width="600px">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="100px">
            <el-form-item label="旧密码" prop="oldPassword">
              <el-input v-model="ruleForm.oldPassword"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input v-model="ruleForm.password"></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="secpassword">
              <el-input v-model="ruleForm.secpassword"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="pwdshow = false">取 消</el-button>
            <el-button type="primary" @click="submitPwd">确 定</el-button>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
  export default {
    mounted() {
      if (this.userInfo.usertype == 1) {
        this.initmenusList = [...this.menusList]
      }
      if (this.userInfo.usertype == 2) {
        this.initmenusList = [...this.menusList2]
      }
      if (localStorage.getItem('menuIndex')) {
        this.menuIndex = String(localStorage.getItem('menuIndex'))
      }
    },
    computed: {
      userInfo() {
        if(!localStorage.getItem('userInfo')) {
          return {}
        }
        return JSON.parse(localStorage.getItem('userInfo'))
      }
    },
    data() {
      return {
        foldFlag: false, //是否折叠菜单
        menuWidth: 120,
        initmenuImgStyle: {
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          margin: '15px 0'
        },
        menuImgStyle: {
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          margin: '15px 0'
        },
        menuIndex: '0',
        initmenusList: [],
        menusList: [{ // 管理员菜单
          name: '商家',
          path: '/shangjia',
          icon: 'el-icon-s-shop',
        },{
          name: '门店',
          path: '/mendian',
          icon: 'el-icon-s-home'
        },{
          name: '员工',
          path: '/yuangong',
          icon: 'el-icon-user-solid'
        },{
          name: '产品',
          path: '/chanpin',
          icon: 'el-icon-s-cooperation'
        },{
          name: '活动',
          path: '/huodong',
          icon: 'el-icon-s-opportunity'
        },{
          name: '订单',
          path: '/dingdan',
          icon: 'el-icon-s-order'
        },{
          name: '提现',
          path: '/tixian',
          icon: 'el-icon-s-claim'
        },{
          name: '会员',
          path: '/huiyuan',
          icon: 'el-icon-s-custom'
        },{
          name: '轨迹',
          path: '/guiji',
          icon: 'el-icon-s-marketing'
        },{
          name: '举报',
          path: '/jubao',
          icon: 'el-icon-warning'
        },{
          name: '配置',
          path: '/setting',
          icon: 'el-icon-s-tools'
        }],
        // 商家菜单
        menusList2: [{
          name: '订单',
          path: '/dingdan',
          icon: 'el-icon-s-order'
        },{
          name: '轨迹',
          path: '/guiji',
          icon: 'el-icon-s-marketing'
        }],
        pwdshow: false,
        ruleForm: {},
        initForm: {
          oldPassword: '',
          password: '',
          secpassword: ''
        },
        rules: {
          oldPassword: [
            { required: true, message: '请输入旧密码', trigger: 'blur' },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入新密码', trigger: 'blur' }
          ],
          secpassword: [
            { required: true, message: '请再次输入新密码', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      foldchange() {
        this.foldFlag = !this.foldFlag
        if (this.foldFlag == true) {
          // 折叠
          this.menuWidth = 50
          this.menuImgStyle.width = '30px'
          this.menuImgStyle.height = '30px'
        }
        if (this.foldFlag == false) {
          // 折叠
          this.menuWidth = 120
          this.menuImgStyle = {...this.initmenuImgStyle}
        }
      },
      link(menu, i) {
        console.log(i)
        localStorage.setItem('menuIndex', i)
        if (menu.path && menu.path !== '') {
          this.$router.push(menu.path)
        }
      },
      logout() {
        // /back/admin/logout
        this.$axios.post(`/back/admin/logout`).then((res)=> {
          if (res.data.code == 200) {
            localStorage.clear()
            this.headersToken = null
            this.$router.push('/Login')
          }
        })
      },
      showPwd() {
        console.log('打开弹窗')
        this.pwdshow = true
        setTimeout(() => {
          this.ruleForm = this.initForm
          this.$refs.ruleForm.clearValidate();
        }, 0)
      },
      submitPwd() {
        this.$message.closeAll()
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (this.ruleForm.password != this.ruleForm.secpassword) {
              this.$message.warning('二次新密码输入不一致！')
              return
            }
            this.saveData()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      saveData() {
        this.$message.closeAll()
        let data = {...this.ruleForm}
        data.masterCode = this.userInfo.masterCode
        data.userType = this.userInfo.usertype
        data.username = this.userInfo.username
        let query = this.$querystring.stringify(data)
        this.$axios.post(`/back/updatePassword?${query}`, data).then((res)=> {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.pwdshow = false
          }
        })
      }
    }
  };
</script>
<style>
  .el-main {
    background: #f5f5fa;
  }
  .el-header {
    background-color: #fff;
    border-bottom: 1px solid #eee;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  }
  .my-menu {
    background: #304156;
    color: #fff;
  }
  .el-menu-item.is-active {
    background: #fff !important;
    font-weight: 600;
  }
  .my-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu-breadcrumb {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .menu-breadcrumb>span {
    font-size: 15px;
    margin-left: 8px;
  }
</style>
<style>
.el-dropdown-menu__item, .el-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
</style>