import { render, staticRenderFns } from "./jubaoList.vue?vue&type=template&id=08a14403"
import script from "./jubaoList.vue?vue&type=script&lang=js"
export * from "./jubaoList.vue?vue&type=script&lang=js"
import style0 from "./jubaoList.vue?vue&type=style&index=0&id=08a14403&prod&coped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports