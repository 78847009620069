<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
      <el-form-item label="商家" prop="masterName">
        <el-select v-model="ruleForm.masterCode" placeholder="请选择" style="width:100%" :disabled="editFlag" clearable>
          <el-option v-for="(item, i) in  selectlist"
          :key="i" :label="item.masterName" :value="item.masterCode"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品" prop="productName">
        <el-input v-model="ruleForm.productName" placeholder="请填写产品名称"></el-input>
      </el-form-item>
      <el-form-item label="价格" prop="price">
        <el-input v-model="ruleForm.price" placeholder="请填写产品价格"></el-input>
      </el-form-item>
      <el-form-item label="产品描述(750*n)" prop="productDes">
        <p style="color:#F56C6C">拖拽图片可更换图片排列顺序</p>
        <div style="display: flex;flex-wrap: wrap;">
          <draggable
            :move="imgMove"
            :list="imgList"
            :animation="200"
            style="display: flex;flex-wrap: wrap;"
          >
          <div v-for="(img, i) in imgList" :key="i" class="imgList-box">
            <i class="el-icon-error img-delete" @click="imgDelete(i)"></i>
            <el-image
              class="imgList-item"
              :src="img.url"
              fit="contain"></el-image>
          </div>
          </draggable>
          <el-upload
            class="uploader"
            :action="$baseUrl+'/back/common/image/upload'"
            :headers="$headers"
            multiple
            :show-file-list="false"
            :on-success="uploadSuccess"
            :on-change="uploadChange">
            <i class="el-icon-plus uploader-icon"></i>
          </el-upload>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
export default {
  name: 'chanpinCreate',
  props: {
  },
  components: { draggable },
  data() {
    return {
      selectlist: [],
      ruleForm: {
      },
      initForm: {
        masterCode: '',
        price: '',
        productName: ''
      },
      rules: {
        masterCode: [
          { required: true, message: '请选择商家', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入产品价格', trigger: 'blur' }
        ]
      },
      editFlag: false,
      imgList: []
    };
  },
  methods: {
    getMasterList() {
      // 获取商家列表
      let url = '/back/masteruser/selectlist'
      this.$axios.post(`${url}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.selectlist = data.rows || []
        }
      })
    },
    getData(data) {
      this.editFlag = false
      this.getMasterList()
      if (data) {
        // 表示编辑
        this.editFlag = true
        this.ruleForm = {...this.ruleForm, ...data}
        this.getproductData(data)
      } else {
        this.ruleForm = this.initForm
        this.imgList = []
      }

    },
    getproductData(data) {
      let url = '/back/product/info'
      this.$axios.get(`${url}?productCode=${data.productCode}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.ruleForm = {...data}
          if(this.ruleForm.describes) {
            let imgList = this.ruleForm.describes.split(',')
            this.imgList = imgList.map((item) =>({url: item}))
          }
        }
      }).catch(()=>{ this.listLoading = false })
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveData()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveData() {
      let url = '/back/product/save'
      let actionType = 'add'
      let dataFormData = new FormData()
      let product = {...this.ruleForm}
      product.describes = this.imgList.map((item) => item.url).join(',')
      let dataForm = {
        actionType,
        masterCode: this.ruleForm.masterCode,
        product: JSON.stringify(product)
      }
      if (this.editFlag == true) {
        dataForm.actionType = 'update'
      }
      for (const key in dataForm) {
        dataFormData.append(key, dataForm[key]);
      }
      this.$axios.post(`${url}`, dataFormData).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.$emit('searchList')
          this.$emit('cancelForm')
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.clearValidate();
    },
    uploadSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        // 上传成功
        this.imgList.push({
          url: response.data
        })
      }
    },
    uploadChange(file, fileList) {
      console.log(fileList)
    },
    imgDelete(i) {
      this.imgList.splice(i, 1)
    },
    imgMove(relatedContext, draggedContext) {
      console.log(relatedContext);
      console.log(draggedContext);
    }
  }
}
</script>
<style scoped>
.imgList-box {
  position: relative;
}
.img-delete {
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 24px;
  color: #f56c6c;
  z-index: 2;
}
</style>