<template>
  <div style="width: 100%;height: 100%" v-loading="loading" :element-loading-text="loadingTxt">
    <div class="map-box">
      <div style="width: calc(50% - 10px);position:relative">
        <el-input placeholder="请输入内容" v-model="mapAddress" id="tipinput">
          <template slot="prepend">关键字</template>
          <template slot="append"><span @click="searchKeyWord">搜索</span></template>
        </el-input>
        <div class="map_search_result" v-if="showSearchResult">
          <div @click="markerResult(item, index)" v-for="(item,index) in poiList" :key="index" class="map_search_result-item"
          :class="selectIndex == index ? 'active' : ''">
            {{item.name}}
          </div>
        </div>
      </div>
      <div style="width: calc(50% - 10px)">
        <el-input readonly v-model="address" id="tipinput">
          <template slot="prepend">已选点位置</template>
        </el-input>
      </div>
    </div>
    <div id="container" :style="{width: '100%', height: '100%'}"></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  name: 'mapPage',
  data() {
    return {
      loading: true,
      loadingTxt: '地图加载中',
      map: null,
      AMap: null,
      address: '',
      geocoder: null,
      geolocation: null,
      pos: {},
      mapAddress: '',
      showSearchResult: false,
      poiList: [],
      placeSearchComponent: null,
      selectIndex: -1
    }
  },
  mounted() {
  },
  methods: {
    initMap(pos){
      this.loading = true
      window._AMapSecurityConfig = {
        securityJsCode: this.mapKey2,
      }
      if (pos) {
        this.pos = pos
      }
      console.log(this.pos)
    AMapLoader.load({
        key: this.mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.ToolBar',
          'AMap.Scale',
          'AMap.Geolocation',
          'AMap.PlaceSearch',
          'AMap.AutoComplete',
          'AMap.Geocoder',
          'AMap.CitySearch'
        ],
        resizeEnable: true,
      }).then((AMap)=>{
        const that = this;
        that.map = new AMap.Map("container",{  //设置地图容器id
          viewMode:"2D",    //是否为3D地图模式
          zoom:12,          //初始化地图级别
          center: ['116.397428', '39.90923']
        });
        that.AMap = AMap
        if (that.pos.longitude && that.pos.latitude) {
          // 经纬度有值，不需要定位
          that.address = that.pos.address
          let position = new AMap.LngLat(that.pos.longitude, that.pos.latitude)
          let marker = new AMap.Marker({  //创建标记
            position
          })
          that.map.clearMap()// 清除所有覆盖物（点标志）
          that.map.add(marker)// 添加点标志
          that.map.setCenter(position)
          that.loading = false
        } else {
          that.getCurrentLocation(AMap);//获取当前定位
        }
        that.geocoder = new AMap.Geocoder()
        that.map.addControl(new AMap.Scale())  // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
        that.map.addControl(new AMap.ToolBar())  //在图面添加鹰眼控件，在地图右下角显示地图的缩略图
        that.handleClick(AMap)//地图选点
        that.mapSearchInit(AMap)
      }).catch(e=>{
        console.log(e);
      })
    },
    //获取当前定位
    getCurrentLocation(AMap){
      const that = this;
      that.geolocation = new AMap.Geolocation({
        timeout: 3000,          //超过3秒后停止定位，默认：5s
        enableHighAccuracy: true,
        zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点
      });
      that.geolocation.getCurrentPosition(function(status,result){
      //备注：getCurrentPosition方法会调用超时或失败：
      //Get geolocation time out：浏览器定位超时，包括原生的超时，可以适当增加超时属性的设定值以减少这一现象。
      //另外还有个别浏览器（如google Chrome浏览器等）本身的定位接口是黑洞，通过其请求定位完全没有回应，也会超时返回失败。
      //Get geolocation failed：定位失败，Chrome、火狐以及部分套壳浏览器接入的定位服务在国外，有较大限制，失败率高。
        console.log(status,result);
        that.loading = false
        if(status=='complete'){
          that.onComplete(result, AMap)
        }else{
          that.onError(result, AMap) //失败后可使用getCityInfo获取非精准定位（具体到省市）
        }
      });
    },
    //解析定位结果
    onComplete(data, AMap) {
      console.log('定位结果：') //经纬度信息
      let lnglat = [data.position.lng, data.position.lat];
      console.log(lnglat)
      let position = new AMap.LngLat(lnglat[0], lnglat[1])
      let marker = new AMap.Marker({  //创建标记
        position
      })
      this.map.clearMap()// 清除所有覆盖物（点标志）
      this.map.add(marker)// 添加点标志
      this.map.setCenter(position)
      let that = this
      //经纬度转换为中文地址详情
      that.geocoder.getAddress(lnglat, function (status, result) {
        console.log(result)
        if (status === 'complete' && result.regeocode) {
          that.address = result.regeocode.formattedAddress;
          that.pos = {
            longitude: lnglat[0],
            latitude: lnglat[1],
            address: that.address
          }
          that.showInfoWindow(marker, AMap);//自定义信息窗体
        } else {
          that.$message.error('根据经纬度查询地址失败')
        }
      })
    },
    //解析定位错误信息
    onError(data, AMap) {
      console.log(data)
      this.getLngLatLocation(AMap)
    },
    //在获取具体定位失败时调用的代码：（非精准定位！！！）
    getLngLatLocation(AMap) {
      const that = this;
      that.geolocation.getCityInfo(function (status, result) {
        if (status === 'complete') {
          let data = result.position
          that.address = result.province + result.city;
          that.showLocation(data, AMap)
        } else {
          that.$message.error('获取地址失败')
        }
      })
    },
    //点击地图获取地理位置
  handleClick(AMap){
    this.map.on('click', (e) => {
      console.log('click', e)
      this.showSearchResult = false
      this.poiList = []
      this.selectIndex = -1
      let lng = e.lnglat.lng
      let lat = e.lnglat.lat
      let marker = new AMap.Marker({
        position: new AMap.LngLat(lng, lat)
      })
      this.map.clearMap()// 清除所有覆盖物（点标志）
      this.map.add(marker)// 添加点标志
      let lnglat = [lng, lat]
      let that = this
      that.geocoder.getAddress(lnglat, function (status, result) {
        console.log(result)
        if (status === 'complete' && result.regeocode) {
          that.address = result.regeocode.formattedAddress;
          that.showInfoWindow(marker, AMap);//自定义信息窗体
          that.pos = {
            address: result.regeocode.formattedAddress,
            longitude: lng,
            latitude: lat
          };
        } else {
          that.$message.error('根据经纬度查询地址失败')
        }
      })
    })
  },
  //新增标记
    showLocation(data, AMap){
      let marker = new AMap.Marker({
        position: new AMap.LngLat( data[0],data[1]) //参数为经纬度
      })
      this.map.clearMap()// 清除所有覆盖物（点标志）
      this.map.add(marker)// 添加点标志
      this.showInfoWindow(marker, AMap);//自定义信息窗体
    },
    //自定义信息窗体
    showInfoWindow(marker, AMap){
      let infoWindow = new AMap.InfoWindow({
        isCustom: true, //是否自定义信息窗体
        content:  `<div style="background-color: white;padding: 0 5px; border-radius: 5px;border: 1px solid #cccccc;"> 地址：${this.address}</div>`,
        closeWhenClickMap: true,
        zIndex: 999,
        offset: new AMap.Pixel(16, -35)
      });
      infoWindow.open(this.map, marker.getPosition());
    },
    /** 初始化搜索 */
  mapSearchInit(AMap){
      let autoOptions = {
        input: "tipInput",
      }
      let autoCompleteComponent= new AMap.Autocomplete(autoOptions);
      this.autoCompleteComponent = autoCompleteComponent;
      // 注册placeSearch组件
      this.placeSearchComponent = new AMap.PlaceSearch()
    },
    //根据输入内容查询
    searchKeyWord(){
      this.loadingTxt = '搜索中'
      this.loading = true
      let that= this
      that.placeSearchComponent.search(that.mapAddress, function (status, result) {
        console.log('搜索', result)
        that.loading = false
        if(status==='complete' && result.info === "OK"){
          that.showSearchResult = true
          that.poiList = result.poiList.pois
        }else{
          that.showSearchResult = false
          that.poiList = []
          that.$message({
            message: "没有查到结果",
            type: "warning",
          });
        }
      })
    },
    //选择搜索的内容
    markerResult(data, index){
      this.selectIndex = index
      let AMap = this.AMap
      this.showsearchResult = false;
      this.address = data.name;
      var marker = new AMap.Marker({
        position: [Number(data.location.lng),Number(data.location.lat)],
      });
      this.map.clearMap()// 清除所有覆盖物（点标志）
      this.map.add(marker)// 添加点标志
      this.showInfoWindow(marker, AMap);
      setTimeout(() => {
        this.map.setCenter(data.location);
        this.map.setZoom(15);
      }, 50)
      this.pos = {
        address: this.address,
        longitude: data.location.lng,
        latitude: data.location.lat
      };
    },
    selectComplate() {
      this.$emit('selectMap', this.pos)
    }
  }

}
</script>

<style>
.map-box {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.map_search_result {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow: auto;
  background: #fff;
  z-index: 2;
}
.map_search_result-item {
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #888;
}
.map_search_result-item.active {
  background: #ecf8ff;
}
</style>