<template>
  <div class="commonListPage">
    <div class="list-opt">
      <el-button type="primary" icon="el-icon-plus" size="medium" plain @click="create(0)">新增门店</el-button>
      <div>
        <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select">
          <el-select v-model="searchTxtType" slot="prepend" placeholder="请选择" clearable>
            <el-option label="门店名称" value="shopName"></el-option>
            <el-option label="商家编码" value="masterCode"></el-option>
            <el-option label="商家名称" value="masterName"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
        </el-input>
      </div>
    </div>
    <div class="info-list">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="8" :md="4" :lg="4" :xl="4" v-for="(item, i) in initInfoList" :key="i">
          <div class="info-content">
            <span>{{item.name}}</span>
            <span>{{attachment[item.key]}}</span>
          </div>
        </el-col>
      </el-row>
      <div class="info-list-action" @click="()=>{openFlag = !openFlag}">
        <span>{{ openFlag ? '收起' : '展开'}}</span>
        <i :class="openFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </div>
    </div>
    <div ref="tableWrap" style="height: calc(100% - 156px)" v-loading="listLoading">
      <el-table
        :data="tableData"
        border
        size="small"
        style="width: 100%;"
        :height="tableHeight"
        :row-class-name="tableRowClassName">
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :fixed="col.fixed || false"
          :prop="col.prop"
          :label="col.label"
          :width="col.width">
          <template slot-scope="scope">
            <template v-if="col.prop == 'isShopwithdraw'">
              <el-switch
                v-model="scope.row.isShopwithdraw"
                active-value="1"
                inactive-value="0"
                @change="upDateisShopwithdraw($event, scope.row)">
              </el-switch>
            </template>
            <template v-else-if="col.prop == 'verificationOtherShop'">
              <el-switch
                v-model="scope.row.verificationOtherShop"
                active-value="1"
                inactive-value="0"
                @change="checkVerificationOtherShop($event, scope.row)">
              </el-switch>
            </template>
            <template v-else-if="col.prop == 'action'">
              <el-button type="primary" icon="el-icon-s-grid" plain size="small" @click="showQRcode(scope.row)"></el-button>
              <el-button type="primary" icon="el-icon-edit-outline" plain size="small" @click="create(1, scope.row)"></el-button>
            </template>
            <span v-else>
              {{  scope.row[col.key] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawerShow"
      size="40%">
      <div class="drawer-content">
        <div class="drawer-content-main">
          <createPage ref="forms" @cancelForm="cancelForm" @searchList="searchList"></createPage>
        </div>
        <div class="drawer-bottom">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="submitForm" :loading="submitloading">{{ submitloading ? '提交中 ...' : '确定' }}</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="门店二维码" :visible.sync="showQRcodeflag" width="300px">
      <div style="text-align: center;">
        <img
        ref="qrcodedom"
        id="qrcodedom"
        style="width: 200px; height: 200px"
        :src="qrCode"/>
        <!-- <el-image
          style="width: 200px; height: 200px"
          ref="qrcodedom"
          id="qrcodedom"
          :src="qrCode" /> -->
        <div class="qrcode-footer">
          <p>{{currentRow.shopName}}</p>
          <!-- <div class="qrcode-footer-btn">
            <el-button type="primary" size="small" @click="saveQrcode(qrCode, currentRow.shopName)">下载</el-button>
          </div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import createPage from './create.vue'
import html2canvas from 'html2canvas';
export default {
  name: 'shangjiaList',
  props: {
  },
  components: {
    createPage
  },
  data() {
    return {
      searchTxt: '',
      searchTxtType: 'shopName',
      infoList: [{
        name: '待入账',
        key: 'rewardAmount',
        val: ''
      },{
        name: '总金额',
        key: 'balance',
        val: ''
      },{
        name: '可提现',
        key: 'sumtotalmoney',
        val: ''
      },{
        name: '提现中',
        key: 'withdrawFrozenBalance',
        val: ''
      },{
        name: '已提现',
        key: 'withdraw_balance',
        val: ''
      },{
        name: '店铺实收',
        key: 'masterGainMoney',
        val: ''
      },{
        name: '分享者收入',
        key: 'sharerGainMoney',
        val: ''
      },{
        name: '交易手续费',
        key: 'platGainMoney',
        val: ''
      }],
      initInfoList: [],
      attachment: {},
      openFlag: false,
      tableData: [],
      column: [{
        label: '门店名称',
        prop: 'shopName',
        key: 'shopName',
        width: 120,
      },{
        label: '商家',
        prop: 'masterName',
        key: 'masterName',
        width: 120,
      },{
        label: '门店编码',
        prop: 'shopCode',
        key: 'shopCode',
        width: 120,
      },{
        label: '联系电话',
        prop: 'phoneNumber',
        key: 'phoneNumber',
        width: 120,
      },{
        label: '员工数量',
        prop: 'staffNum',
        key: 'staffNum',
        width: 120,
      },{
        label: '创建时间',
        prop: 'createTime',
        key: 'createTime',
        width: 200,
      },{
        label: '门店地址',
        prop: 'address',
        key: 'address',
        width: 220,
      },{
        label: '总营收',
        prop: 'sumtotalmoney',
        key: 'sumtotalmoney',
        width: 150,
      },{
        label: '总提现',
        prop: 'withdraw_balance',
        key: 'withdraw_balance',
        width: 150,
      },{
        label: '提现中',
        prop: 'withdrawFrozenBalance',
        key: 'withdrawFrozenBalance',
        width: 150,
      },{
        label: '可提现',
        prop: 'platGainMoney',
        key: 'platGainMoney',
        width: 150,
      },{
        label: '待入账',
        prop: 'rewardAmount',
        key: 'rewardAmount',
        width: 150,
      },{
        label: '交易手续费',
        prop: 'platGainMoney',
        key: 'platGainMoney',
        width: 150,
      },{
        label: '是否可提现',
        prop: 'isShopwithdraw',
        key: 'isShopwithdraw',
        width: 150,
      },{
        label: '是否支持门店跨店核销',
        prop: 'verificationOtherShop',
        key: 'verificationOtherShop',
        width: 150,
      },{
        label: '操作',
        prop: 'action',
        key: 'action',
        width: 120,
        fixed: 'right'
      }],
      listLoading: true,
      params: {
        currPage: 1,
        pageSize: 10
      },
      totalCount: 1,
      totalPage: 1,
      tableHeight: 400,
      drawerTitle: '新增门店',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {}, //商家提现对象
      showPZflag: false, //批注弹窗显示
      pzContent: '',
      showQRcodeflag: false, //员工二维码弹窗
      fileShow: false, //商家资料弹窗
      qrCode: '',
      currentRow: {}
    };
  },
  mounted() {
    this.setTableHeight()
    this.searchList()
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    handleResize() {
      console.log('窗口变')
      this.setTableHeight()
    },
    searchList() {
      this.params = {}
      this.params.currPage = 1
      this.params.pageSize = 10
      this.getData()
    },
    getData() {
      this.params[this.searchTxtType] = this.searchTxt
      this.listLoading = true
      let url = '/back/master/shop/list'
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.list
          this.totalPage = data.totalPage
          this.totalCount = data.totalCount
          this.attachment = data.attachment || {}
        }
      }).catch(()=>{ this.listLoading = false })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(page) {
      this.params.currPage = page
      this.getData()
    },
    setTableHeight() {
      this.$nextTick(() => {
        if (this.smFlag == 1) {
          console.log('小小小')
          this.column[this.column.length -1].fixed = false
        }
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    },
    create(type, row) {
      this.drawerShow = true
      if (type == 0) {
        this.drawerTitle = '新增门店'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData()
        }, 0)
      }
      if (type == 1) {
        this.drawerTitle = '编辑门店'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData(row)
        }, 0)
      }
    },
    openSJFile(row) {
      //  打开商家资料
      this.fileShow = true
      setTimeout(() => {
        this.$refs.fileFrom.resetForm()
        this.$refs.fileFrom.getData(row)
      }, 0)
    },
    cancelFileFrom() {
      this.$refs.fileFrom.resetForm()
      this.fileShow = false
    },
    submitFileForm() {
      this.$refs.fileFrom.submitForm()
    },
    submitForm() {
      this.$refs.forms.submitForm()
    },
    cancelForm() {
      this.$refs.forms.resetForm()
      this.drawerShow = false
    },
    upDateisShopwithdraw(e, row) {
      let query = this.$querystring.stringify({
        shopCode: row.shopCode,
        isShopwithdraw: e
      })
      this.$axios.post(`/back/master/shop/checkIsShopwithdraw?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
        } else {
          // 没有成功，把状态还原
          row.isShopwithdraw = Number(row.isShopwithdraw) === 0 ? '1' : '0'
        }
      })
    },
    checkVerificationOtherShop(e, row) {
      let query = this.$querystring.stringify({
        shopCode: row.shopCode,
        verificationOtherShop: e
      })
      this.$axios.post(`/back/master/shop/checkVerificationOtherShop?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
        } else {
          // 没有成功，把状态还原
          row.verificationOtherShop = Number(row.verificationOtherShop) === 0 ? '1' : '0'
        }
      })
    },
    showTX() {
      this.showTXflag = true
    },
    submitTX() {
      this.showTXflag = false
    },
    showPZ() {
      this.showPZflag = true
    },
    submitPZ() {
      this.showPZflag = false
    },
    BDswitch(row) {
      if (row.bindingFlag == 0) {
        // 需要绑定
        this.$confirm('是否需要绑定该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 1
        }).catch(() => {         
        });
      }
      if (row.bindingFlag == 1) {
        // 需要解绑
        this.$confirm('是否需要解绑该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 0
        }).catch(() => {         
        });
      }
    },
    showQRcode(row) {
      console.log(row)
      this.currentRow = row
      let query = {
        appId: 'wxc21ffa456349da82',
        page: 'pages/homeIndex',
        scene: ';;;' + row.shopCode + ';71016',
        width: 400
      }
      this.$axios.post(`/back/createWxQrCode`, query).then((res)=> {
        if (res.data.code == 200) {
          this.showQRcodeflag = true
          this.qrCode = res.data.data
        }
      })
    },
    deleteRow(row) {
      this.$confirm('是否删除该商家?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
      }).catch(() => {         
      });
    },
    async saveQrcode() {
      const element = document.getElementById('qrcodedom') // 需要截图的元素的ID
      const canvas = await html2canvas(element,{
        useCORS: true
      });
      const img = canvas.toDataURL('image/png'); // 转换为DataURL
      // 创建一个a标签模拟点击进行下载
      const a = document.createElement('a');
      a.href = img;
      a.download =  `${this.currentRow.shopName}.png`;
      a.click();
    },
    downloadByBlob(url,name) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
         let url = URL.createObjectURL(blob)
         this.download(url,name)
         // 用完释放URL对象
         URL.revokeObjectURL(url)
       })
     }
   },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    // imgUrl为图片路径，fileName为文件名
    handleDownload(imgUrl, fileName){
      let url = imgUrl.match(/images(\S*)/)[1];
      this.$axios.get(url, { responseType: 'arraybuffer' })
      .then((res) => {
        const blob = new Blob([res.data])
        const blobUrl = window.URL.createObjectURL(blob)
        // 创建a标签并隐藏 设置标签属性为download时会强制下载
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = blobUrl
        a.setAttribute('download', fileName)
        // 清除a标签
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(blobUrl)
      })
    }
  },
  watch: {
    openFlag: {
      handler: function (val) {
        if (val) {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList))
        } else {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList.slice(0, 6)))
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 120px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
.qrcode-footer {
  position: relative;
}
.qrcode-footer-btn {
  margin-top: 10px;
  text-align: right;
}
</style>