<template>
  <div class="commonListPage">
    <div class="list-opt">
      <div style="display: flex;align-items: center;flex-wrap:wrap">
        <div style="margin-right: 10px">
          <el-checkbox
            style="margin-right: 10px"
            @change="masterOrderChange">商家订单</el-checkbox>
          <!-- <span style="font-size: 14px;margin-right: 10px">活动类型</span>
          <el-select v-model="params.activityType" placeholder="请选择" style="width: 120px" clearable>
            <el-option label="拼团" value="Assemble"></el-option>
            <el-option label="秒杀" value="Seckill"></el-option>
            <el-option label="集赞" value="Fabulous"></el-option>
            <el-option label="推荐" value="Partner"></el-option>
          </el-select> -->
        </div>
        <div style="margin-right: 10px">
          <el-select
            @change="searchList"
            v-model="params.isOpenGroup"
            placeholder="请选择"
            style="width: 120px"
            clearable>
            <el-option v-for="(item, i) in isOpenGroupList"
            :key="i" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </div>
        <div style="margin-right: 10px">
          <span style="font-size: 14px;margin-right: 10px">商家</span>
          <el-select
            @change="searchList"
            v-model="params.masterCode"
            placeholder="请选择"
            style="width: 150px"
            clearable>
            <el-option v-for="(item, i) in masterList"
              :key="i" :label="item.masterName" :value="item.masterCode"></el-option>
          </el-select>
        </div>
        <el-date-picker
          style="margin-right: 10px;width: 360px"
          v-model="dateTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          placeholder="选择时间范围">
        </el-date-picker>
        <div style="width: 300px;margin-right: 10px">
          <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select">
            <el-select v-model="searchTxtType" slot="prepend" placeholder="请选择" clearable>
              <el-option label="商家名称" value="masterName"></el-option>
              <el-option label="门店名称" value="shopName"></el-option>
              <el-option label="订单号" value="orderno"></el-option>
              <el-option label="手机号" value="tel"></el-option>
              <el-option label="一级姓名" value="shareMemberNick"></el-option>
              <el-option label="一级手机" value="shareTel"></el-option>
              <el-option label="核销部门" value="saleName"></el-option>
              <el-option label="会员名称" value="memberNick"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
          </el-input>
        </div>
        <div>
          <el-button type="primary" @click="exportFun">导出</el-button>
        </div>
      </div>
    </div>
    <div class="info-list">
      <el-row :gutter="20">
        <el-col  :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-for="(item, i) in initInfoList" :key="i">
          <div class="info-content">
            <span>{{item.name}}</span>
            <span>{{attachment[item.key]}}</span>
          </div>
        </el-col>
      </el-row>
      <div class="info-list-action" @click="()=>{openFlag = !openFlag}">
        <span>{{ openFlag ? '收起' : '展开'}}</span>
        <i :class="openFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </div>
    </div>
    <div ref="tableWrap" style="height: calc(100% - 206px)" v-loading="listLoading">
      <el-tabs type="border-card" v-model="params.orderStatus" @tab-click="searchList">
        <el-tab-pane label="全部"></el-tab-pane>
        <el-tab-pane label="待支付" name="1"></el-tab-pane>
        <el-tab-pane label="待核销" name="2"></el-tab-pane>
        <el-tab-pane label="已核销" name="3"></el-tab-pane>
        <el-tab-pane label="已取消" name="4"></el-tab-pane>
        <!-- <el-tab-pane label="退款中" name="5"></el-tab-pane> -->
        <!-- <el-tab-pane label="已退款" name="6"></el-tab-pane> -->
        <el-tab-pane label="已撤单" name="7"></el-tab-pane>
        <el-table
          border
          :data="tableData"
          size="small"
          style="width: 100%;"
          :height="tableHeight"
          :row-class-name="tableRowClassName">
          <el-table-column
            v-for="(col, i) in column"
            :key="i"
            :fixed="col.fixed || false"
            :prop="col.prop"
            :label="col.label"
            :width="col.width">
            <template slot-scope="scope">
              <el-image
              v-if="col.prop == 'headUrl'"
                style="width: 60px; height: 60px"
                :src="scope.row.headUrl"
                :preview-src-list="[scope.row.headUrl]">
              </el-image>
              <template v-else-if="col.prop == 'memberNick'">
                <p>{{scope.row.memberNick}}</p>
                <p>{{scope.row.tel}}</p>
              </template>
              <template v-else-if="col.prop == 'orderStatus'">
                <p>{{orderStatusMap[scope.row.orderStatus]}}</p>
              </template>
              <template v-else-if="col.prop == 'activityType'">
                <p>{{activityTypeMap[scope.row.activityType]}}</p>
              </template>
              <div class="option-box" v-else-if="col.prop == 'action'">
                <el-button v-if="scope.row.orderStatus != 1" type="primary" icon="el-icon-edit-outline" plain size="small" @click="openFxr(scope.row)"></el-button>
                <el-button v-if="scope.row.orderStatus == 2 || scope.row.orderStatus == 3"
                  type="primary"
                  icon="el-icon-refresh-left"
                  plain
                  size="small"
                  @click="openBack(scope.row)"></el-button>
                <!-- 新增待支付操作：取消订单 -->
                <el-button v-if="scope.row.orderStatus == 1"
                           type="primary"
                           icon="el-icon-refresh-right"
                           plain
                           size="small"
                           @click="cancelOrder(scope.row)"></el-button>
              </div>
              <span v-else>
                {{  scope.row[col.key] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-tabs>
      <div class="bottom-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="修改订单一级分享人及红包" :visible.sync="showPZflag" width="600px">
      <el-form label-width="120px">
        <el-form-item label="分享人手机号" prop="pzContent">
          <el-input v-model="pzContent" placeholder="请填写分享人手机号"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPZflag = false">取 消</el-button>
        <el-button type="primary" @click="submitPZ">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="撤单" :visible.sync="showBack">
      <el-form label-width="120px">
        <el-form-item label="撤单原因" required>
          <el-input type="textarea" v-model="backReason" placeholder="请填写撤单原因"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showBack = false">取 消</el-button>
        <el-button type="primary" @click="submitBack">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'dingdanList',
  props: {
  },
  components: {
  },
  data() {
    return {
      dateTime: [],
      searchTxt: '',
      searchTxtType: 'masterName',
      searchParams: {
        masterName: '',
        merchantOrderFlag: false,
        merchantOrder: ''
      },
      infoList: [{
        name: '成交订单数量',
        key: 'sumbuynum',
        val: ''
      },{
        name: '成交订单金额',
        key: 'sumall',
        val: ''
      },{
        name: '总商家实收',
        key: 'summastergainmoney',
        val: ''
      },{
        name: '总分享者收入',
        key: 'totalShare',
        val: ''
      },{
        name: '一级分享者收入',
        key: 'sumsharergainmoney',
        val: ''
      },{
        name: '交易手续费',
        key: 'sumplatgainmoney',
        val: ''
      },{
        name: '成团返差额',
        key: 'cashback',
        val: ''
      }],
      initInfoList: [],
      openFlag: false,
      tableData: [],
      column: [{
        label: '商家名称',
        prop: 'masterName',
        key: 'masterName',
        fixed: 'left',
        width: 120
      },{
        label: '门店名称',
        prop: 'shopName',
        key: 'shopName',
        fixed: 'left',
        width: 120
      },{
        label: '头像',
        prop: 'headUrl',
        key: 'headUrl',
        width: 100
      },{
        label: '会员',
        prop: 'memberNick',
        key: 'memberNick',
        width: 140
      },{
        label: '商家实收',
        prop: 'masterGainMoney',
        key: 'masterGainMoney',
        width: 120
      },{
        label: '一级分享者姓名',
        prop: 'sharerName',
        key: 'sharerName',
        width: 120
      },{
        label: '一级分享手机号',
        prop: 'sharerTel',
        key: 'sharerTel',
        width: 120
      },{
        label: '一级分享者收入',
        prop: 'sharerGainMoney',
        key: 'sharerGainMoney',
        width: 120
      },{
        label: '二级分享者姓名',
        prop: 'sharerTwoName',
        key: 'sharerTwoName',
        width: 120
      },{
        label: '二级分享手机号',
        prop: 'sharerTwoTel',
        key: 'sharerTwoTel',
        width: 120
      },{
        label: '二级分享者收入',
        prop: 'sharerTwoGainMoney',
        key: 'sharerTwoGainMoney',
        width: 120
      },{
        label: '订单号',
        prop: 'orderno',
        key: 'orderno',
        width: 120
      },{
        label: '付款时间',
        prop: 'payTime',
        key: 'payTime',
        width: 140
      },{
        label: '产品名称',
        prop: 'activityName',
        key: 'activityName',
        width: 120
      },{
        label: '交易手续费', //0
        prop: 'platGainMoney',
        key: 'platGainMoney',
        width: 100
      },{
        label: '购买数量',
        prop: 'buyNum',
        key: 'buyNum',
        width: 100
      },{
        label: '订单状态',
        prop: 'orderStatus',
        key: 'orderStatus',
        width: 120
      },{
        label: '购买类型',
        prop: 'activityType',
        key: 'activityType',
        width: 120
      },{
        label: '成团返差额',
        prop: 'cashback',
        key: 'cashback',
        width: 120
      },{
        label: '成团人数', // ??
        prop: 'clusteringNumber',
        key: 'clusteringNumber',
        width: 120
      },{
        label: '核销部门',
        prop: 'saleName',
        key: 'saleName',
        width: 120
      },{
        label: '核销时间',
        prop: 'saleDate',
        key: 'saleDate',
        width: 120
      },{
        label: '下单第次',
        prop: 'orderNum',
        key: 'orderNum',
        width: 100
      },{
        label: '赠品信息',
        prop: 'giftProduct',
        key: 'giftProduct',
        width: 150
      },{
        label: '订单金额',
        prop: 'totalmoney',
        key: 'totalmoney',
        width: 100
      },{
        label: '撤单原因',
        prop: 'reverseReason',
        key: 'reverseReason',
        width: 140
      },{
        label: '撤单时间',
        prop: 'reverseTime',
        key: 'reverseTime',
        width: 140
      },{
        label: '撤单人',
        prop: 'reverser',
        key: 'reverser',
        width: 120
      },{
        label: '备注信息',
        prop: 'remarksInfo',
        key: 'remarksInfo',
        width: 150
      },{
        label: '操作',
        prop: 'action',
        key: 'action',
        width: 120,
        fixed: 'right'
      }],
      params: {
        currPage: 1,
        pageSize: 10,
        orderStatus: '',
        isOpenGroup: '',
        masterCode: ''
      },
      initParams: {
        currPage: 1,
        pageSize: 10,
        orderStatus: '',
        isOpenGroup: '',
        masterCode: ''
      },
      totalCount: 0,
      tableHeight: 400,
      drawerTitle: '新增',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {}, //商家提现对象
      showPZflag: false, //批注弹窗显示
      pzContent: '',
      showQRcodeflag: false, //员工二维码弹窗
      showRecord: false, //员工记录弹窗
      attachment: {},
      listLoading: true,
      orderStatusMap: {
        //订单状态(1：待支付，2：待核销，3：已核销,4:已取消,5:退款中,6:已退款)
        1: '待支付',
        2: '待核销',
        3: '已核销',
        4: '已取消',
        5: '退款中',
        6: '已退款'
      },
      isOpenGroupMap: {
        //购买类型 1开团 0参团 3抢购
        0: '参团',
        1: '开团',
        3: '抢购'
      },
      isOpenGroupList: [{
        name: '参团',
        value: 0
      },{
        name: '开团',
        value: 1
      },{
        name: '抢购',
        value: 3
      }],
      activityTypeMap: {
        'Assemble': '拼团',
        'Seckill': '秒杀',
        'Fabulous': '集赞',
        'Partner': '推荐',
      },
      showBack: false,
      backReason: '',
      currentRow: null,
      masterList: []
    };
  },
  computed: {
      userInfo() {
        if(!localStorage.getItem('userInfo')) {
          return {}
        }
        return JSON.parse(localStorage.getItem('userInfo'))
      }
    },
  created() {
  },
  mounted() {
    // if (this.userInfo.usertype == 2) {
    //   this.column.pop()
    // }
    this.getmasterList()
    this.setTableHeight()
    this.searchList()
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    handleResize() {
      console.log('窗口变')
      this.setTableHeight()
    },
    getmasterList() {
      let url = '/back/masteruser/selectlist'
      this.$axios.post(`${url}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.masterList = data.rows || []
        }
      })
    },
    searchList() {
      delete this.params.masterName
      delete this.params.shopName
      delete this.params.orderno
      delete this.params.tel
      delete this.params.shareMemberNick
      delete this.params.shareTel
      delete this.params.saleName
      delete this.params.memberNick
      this.params.currPage = 1
      this.params.pageSize = 10
      this.getData()
    },
    masterOrderChange(e) {
      console.log(e)
      this.params.masterOrder = e == true ? 1 : 0
      this.searchList()
    },
    getData() {
      this.dateTime = this.dateTime || []
      this.params[this.searchTxtType] = this.searchTxt
      this.params.payTimeStart = this.dateTime[0] || ''
      this.params.payTimeEnd = this.dateTime[1] || ''
      this.listLoading = true
      let url = '/back/activity/order/list'
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.list || []
          this.totalCount = data.totalCount
          this.attachment = data.attachment || {}
          this.attachment.totalShare = Number(data.attachment.sumsharergainmoney) + Number(data.attachment.sumsharertwogainmoney)
        }
      }).catch(()=>{ this.listLoading = false })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(page) {
      this.params.currPage = page
      this.getData()
    },
    setTableHeight() {
      this.$nextTick(() => {
        if (this.smFlag == 1) {
          console.log('小小小')
          this.column[this.column.length -1].fixed = false
          this.column[0].fixed = false
          this.column[1].fixed = false
        }
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    },
    exportFun() {
      // 导出数据
      let url = '/back/activity/order/excelList'
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios({
        url: `${url}?${query}`,
        method: 'post',
        responseType: 'blob',
      }).then((res) => {
        console.log(res)
        const file = new Blob([res.data], { type: "application/vnd.ms-excel" });
        const url = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.download = '订单.xls';
        a.href = url;
        a.click();
        this.$message.success('导出成功')
      }).catch(()=>{})
    },
    // downloadFile(res, fileName) {
    //   let blob = new Blob([res.data]); //res为从后台返回的数据
    //   if (!fileName) {
    //       fileName = res.headers['content-disposition'].split('filename=').pop();
    //   }
    //   if ('msSaveOrOpenBlob' in navigator) {
    //       window.navigator.msSaveOrOpenBlob(blob, fileName);
    //   } else {
    //       const elink = document.createElement('a');
    //       elink.download = fileName;
    //       elink.style.display = 'none';
    //       elink.href = URL.createObjectURL(blob);
    //       document.body.appendChild(elink);
    //       elink.click();
    //       URL.revokeObjectURL(elink.href);
    //       document.body.removeChild(elink);
    //     }
    // },
    create(type, row) {
      this.drawerShow = true
      if (type == 0) {
        this.drawerTitle = '新增'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData()
        }, 0)
      }
      if (type == 1) {
        this.drawerTitle = '编辑产品'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData(row)
        }, 0)
      }
    },
    openFxr(row) {
      //  打开商家资料
      this.showPZflag = true
      this.pzContent = ''
      this.currentRow = row
      console.log(row)
    },
    cancelFileFrom() {
      this.$refs.fileFrom.resetForm()
      this.fileShow = false
    },
    submitFileForm() {
      this.$refs.fileFrom.submitForm()
    },
    submitForm() {
      this.$refs.forms.submitForm()
    },
    cancelForm() {
      this.$refs.forms.resetForm()
      this.drawerShow = false
    },
    showTX() {
      this.showTXflag = true
    },
    submitTX() {
      this.showTXflag = false
    },
    submitPZ() {
      let row = this.currentRow
      let query = this.$querystring.stringify({
        orderno: row.orderno,
        tel: this.pzContent
      })
      this.$axios.post(`/back/activity/order/editSharer?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getData()
        }
      })
      this.showPZflag = false
    },
    BDswitch(row) {
      if (row.bindingFlag == 0) {
        // 需要绑定
        this.$confirm('是否需要绑定该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 1
        }).catch(() => {
        });
      }
      if (row.bindingFlag == 1) {
        // 需要解绑
        this.$confirm('是否需要解绑该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 0
        }).catch(() => {
        });
      }
    },
    showQRcode(row) {
      console.log(row)
      this.showQRcodeflag = true
    },
    openBack(row) {
      this.currentRow = row
      this.showBack = true
      this.backReason = ''
    },
    submitBack() {
      if (!this.backReason) {
        this.$message.warning('请填写撤单原因！')
        return
      }
      this.$confirm('是否撤回该订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.reverseOrder()
      }).catch(() => {
      });
    },
    reverseOrder() {
      let row = this.currentRow
      let query = this.$querystring.stringify({
        orderno: row.orderno,
        reason: this.backReason
      })
      this.$axios.post(`/back/activity/revert?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getData()
        }
      })
    },
    openRecord() {
      this.showRecord = true
    },
    cancelOrder(row) {
      this.currentRow = row
      this.$confirm('是否取消该订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.cancelOrderApi()
      }).catch(() => {
      });
    },
    cancelOrderApi() {
      let row = this.currentRow
      let query = this.$querystring.stringify({
        orderNo: row.orderno
      })
      this.$axios.post(`/back/activity/seckill/cancelOrder?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getData()
        }
      })
    }
  },
  watch: {
    openFlag: {
      handler: function (val) {
        if (val) {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList))
        } else {
          let arr = [this.infoList[0]]
          this.initInfoList = arr
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 100px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
.option-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
