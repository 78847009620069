<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
      <el-form-item label="员工名称" prop="memberNick">
        <el-input v-model="ruleForm.memberNick" placeholder="请填写员工名称"></el-input>
      </el-form-item>
      <el-form-item label="门店" prop="storeName">
        <el-select v-model="ruleForm.shopCode" placeholder="请选择" style="width:100%" clearable>
          <el-option v-for="(item, i) in  selectlist"
          :key="i" :label="item.shopName" :value="item.shopCode"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input v-model="ruleForm.tel" disabled></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'mendianCreate',
  props: {
  },
  data() {
    return {
      ruleForm: {
      },
      initForm: {
        memberCode: '',
        memberNick: '',
        storeCode: '',
        storeName: '',
        tel: '',
      },
      rules: {
        memberNick: [
          { required: true, message: '请输入员工名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        storeCode: [
          { required: true, message: '请选择门店', trigger: 'blur' }
        ]
      },
      editFlag: false,
      selectlist: []
    };
  },
  methods: {
    getShopList(row) {
      // 获取门店列表
      let url = '/back/master/shop/shopListByMasterCode'
      let query = this.$querystring.stringify({
        masterCode: row.masterCode
      })
      this.$axios.post(`${url}?${query}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.selectlist = data.data || []
        }
      })
    },
    getData(data) {
      if (data) {
        // 表示编辑
        this.getShopList(data)
        this.editFlag = true
        this.ruleForm = {...this.ruleForm, ...data}
      } else {
        this.ruleForm = this.initForm
      }
    },  
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveData()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveData() {
      let url = '/back/vipMember/edit'
      let dataForm = {
        memberCode: this.ruleForm.memberCode,
        memberNick: this.ruleForm.memberNick,
        shopCode: this.ruleForm.shopCode,
        tel: this.ruleForm.tel
      }
      let query = this.$querystring.stringify(dataForm)
      this.$axios.post(`${url}?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.$emit('searchList')
          this.$emit('cancelForm')
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.clearValidate();
    }
  }
}
</script>