<template>
  <div class="loginPage">
    <el-image
      style="width: 100%; height: 100%"
      :src="require('../assets/login.png')"
      fit="cover"></el-image>
      <div class="loginForm">
        <el-image style="width: 150px;height:150px;border-radius: 50%;" :src="require('../assets/logo.jpg')"></el-image>
        <div class="loginForm-box">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" label-position="left" class="ruleForm">
            <el-form-item label="用户名" prop="userName">
              <el-input v-model="ruleForm.userName" placeholder="请输入用户名">
                <template slot="prepend">
                  <i class="el-icon-user"></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pwd" @keyup.enter.native="handleEnterKey">
              <el-input show-password v-model="ruleForm.pwd" placeholder="请输入密码">
                <template slot="prepend">
                  <i class="el-icon-lock"></i>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" class="loginBtn" @click="login">登录</el-button>
        </div>
      </div>
      <div class="bottom-footer">
        <p class="bottom-txt">深圳市喜百瑞文化传播有限公司</p>
        <p class="bottom-txt">粤ICP备2024201869号-1</p>
      </div>
  </div>
</template>
<script>
export default {
  name: 'loginPage',
  props: {
  },
  data() {
    return {
      ruleForm: {
        userName: '',
        pwd: ''
      },
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    login() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // let data = {
          //   username: this.ruleForm.userName,
          //   password: this.ruleForm.pwd
          // }
          this.$axios.post(`/back/anon/admin/login?username=${this.ruleForm.userName}&password=${this.ruleForm.pwd}`).then((res)=> {
            console.log(res)
            if (res.data.code == 200) {
              localStorage.setItem('token', res.data.data.token)
              localStorage.setItem('userInfo', JSON.stringify(res.data.data.user))
              this.$router.push('/')
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleEnterKey() {
      this.login()
    }
  }
}
</script>
<style scoped>
.loginPage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.loginForm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginForm-box {
  width: 450px;
  height: 300px;
  background: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);
  border-radius: 12px;
  padding: 25px 40px;
  padding-top: 40px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 18vh;
}
.loginBtn {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 40px;
}
.bottom-txt {
  font-size: 12px;
  color: #666;
  line-height: 20px;
}
.bottom-footer {
  width: 100%;
  position: absolute;
  bottom: 40px;
  text-align: center;
}
</style>
