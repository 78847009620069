<template>
  <div class="mainPage">
    <el-form :model="initForm" :rules="rules" ref="ruleForm" label-width="80px" class="ruleForm">
      <el-form-item label="提现类型" prop="withdrawalType">
        <el-radio-group v-model="initForm.withdrawalType">
          <el-radio label="alipay">支付宝</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="打款方式" prop="isAuto">
        <el-radio-group v-model="initForm.isAuto">
          <el-radio label="0">人工</el-radio>
          <el-radio label="1">自动</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="pageFooter">
      <el-button type="primary" @click="submitForm">保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'shangjiaCreate',
  props: {
  },
  data() {
    return {
      initForm: {
        withdrawalType: '',
        isAuto: ''
      },
      rules: {
        withdrawalType: [
          { required: true, message: '请选择提现类型', trigger: 'change' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        isAuto: [
          { required: true, message: '请选择打款方式', trigger: 'change' }
        ]
      }
    };
  },
  mounted() {
    this.getData()
  },
  methods: { 
    getData() {
      // 获取配置信息
      let url = '/back/config/getConfig'
      this.$axios.post(`${url}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.initForm = data
        }
      })
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveData()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveData() {
      this.$message.closeAll()
      let url = '/back/config/setMemberUrlConfig'
      this.initForm.url = this.$baseUrl
      let query = this.$querystring.stringify(this.initForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('保存成功')
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.clearValidate();
    }
  }
}
</script>
<style scoped>
.pageFooter {
  padding: 20px 15px;
  text-align: left;
}
</style>