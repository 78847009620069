<template>
  <div style="padding: 0 10px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" class="ruleForm">
      <el-form-item label="商家名称" prop="masterName">
        <el-input v-model="ruleForm.masterName" :readonly="true"></el-input>
      </el-form-item>
      <el-form-item label="商家地址" prop="detailInfo">
        <el-input type="textarea" v-model="ruleForm.detailInfo" :readonly="true"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input v-model="ruleForm.tel" placeholder="请填写联系电话"></el-input>
      </el-form-item>
      <el-form-item label="联系二维码(尺寸建议90*90)" prop="qrCode">
        <el-upload
          :action="$baseUrl+'/back/common/image/upload'"
          :headers="$headers"
          list-type="picture-card"
          :file-list="userimageList"
          :on-success="handleSuccess"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="商家logo(尺寸建议90*90)" prop="logo">
        <el-upload
          :action="$baseUrl+'/back/common/image/upload'"
          :headers="$headers"
          list-type="picture-card"
          :file-list="logoList"
          :on-success="handleSuccess2"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove2">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="主页顶图(尺寸建议750*445)" prop="mainImg">
        <el-upload
          :action="$baseUrl+'/back/common/image/upload'"
          :headers="$headers"
          list-type="picture-card"
          :file-list="mainImgList"
          :on-success="handleSuccess3"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove3">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="商家分享图(尺寸建议750*n)" prop="shareImg">
        <el-upload
          :action="$baseUrl+'/back/common/image/upload'"
          :headers="$headers"
          list-type="picture-card"
          :file-list="shareimageList"
          :on-success="handleSuccess4"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove4">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="关注二维码(尺寸建议400*400)" prop="followQRcode">
        <el-upload
          :action="$baseUrl+'/back/common/image/upload'"
          :headers="$headers"
          list-type="picture-card"
          :file-list="followimageList"
          :on-success="handleSuccess5"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove5">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'shangjiaCreate',
  props: {
  },
  data() {
    return {
      ruleForm: {
      },
      initForm: {},
      rules: {
        // address: [
        //   { required: true, message: '请输入商家地址', trigger: 'blur' }
        // ]
      },
      imgDialogShow: false,
      dialogImageUrl: '',
      userimageList: [],
      logoList: [],
      mainImgList: [],
      shareimageList: [],
      followimageList: []
    };
  },
  methods: {
    getData(data) {
      let url = '/back/masteruser/detailsShopList'
      let query = this.$querystring.stringify({
        currPage: 1,
        pageSize: 20,
        page: 1,
        masterCode: data.masterCode
      })
      this.$axios.post(`${url}?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data.masterUser
          if (this.ruleForm.logo && this.ruleForm.logo != '') {
            if (JSON.parse(this.ruleForm.logo).logo) {
              this.logoList = JSON.parse(this.ruleForm.logo).logo.split(',').map((item) =>({url: item}))
            }
            if (JSON.parse(this.ruleForm.logo).shareimage) {
              this.shareimageList = JSON.parse(this.ruleForm.logo).shareimage.split(',').map((item) =>({url: item}))
            }
            if (JSON.parse(this.ruleForm.logo).masterindex) {
              this.mainImgList = JSON.parse(this.ruleForm.logo).masterindex.split(',').map((item) =>({url: item}))
            }
          }
          this.fileListInit()
        }
      })
    }, 
    fileListInit() {
      let arr= [{
        filekey: 'userimage',
        listKey: 'userimageList'
      },{
        filekey: 'shareimage',
        listKey: 'shareimageList'
      },{
        filekey: 'followimag',
        listKey: 'followimageList'
      }]
      arr.forEach((item) => {
        if (this.ruleForm[item.filekey] && this.ruleForm[item.filekey] != '') {
            this[item.listKey] = [{
              url: this.ruleForm[item.filekey]
            }]
          }
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.userimageList = fileList
    },
    handleRemove2(file, fileList) {
      console.log(file, fileList);
      this.logoList = fileList
    },
    handleRemove3(file, fileList) {
      console.log(file, fileList);
      this.mainImgList = fileList
    },
    handleRemove4(file, fileList) {
      console.log(file, fileList);
      this.shareimageList = fileList
    },
    handleRemove5(file, fileList) {
      console.log(file, fileList);
      this.followimageList = fileList
    },
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        this.userimageList.push({
          url: response.data
        })
      }
    },
    handleSuccess2(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        this.logoList.push({
          url: response.data
        })
      }
    },
    handleSuccess3(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        this.mainImgList.push({
          url: response.data
        })
      }
    },
    handleSuccess4(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        this.shareimageList.push({
          url: response.data
        })
      }
    },
    handleSuccess5(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        this.followimageList.push({
          url: response.data
        })
      }
    },
    handlePictureCardPreview() {
    }, 
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveData()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveData() {
      let url = '/back/masteruser/modifyRemarks'
      let form = {...this.ruleForm}
      form.logos = JSON.stringify({
        logo: this.logoList.map((item) => {return item.url}).join(','),
        masterindex: this.mainImgList.map((item) => {return item.url}).join(','),
        shareimage: this.shareimageList.map((item) => {return item.url}).join(',')
      })
      form.userimage = this.userimageList.map((item) => {return item.url}).join(',')
      form.followimage = this.followimageList.map((item) => {return item.url}).join(',')
      console.log(form)
      let query = this.$querystring.stringify(form)
      this.$axios.post(`${url}?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success('保存成功')
          this.$emit('searchList')
          this.$emit('cancelForm')
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.clearValidate();
    }
  }
}
</script>