<template>
  <div class="commonListPage">
    <div class="list-opt" style="justify-content: flex-end;">
      <div style="display: flex;align-items: center;flex-wrap:wrap">
        <el-date-picker
          style="margin-right: 10px;width: 360px"
          v-model="dateTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          placeholder="选择时间范围"
          @blur="searchList">
        </el-date-picker>
      </div>
    </div>
    <div class="info-list"></div>
    <div ref="tableWrap" style="height: calc(100% - 126px)" v-loading="listLoading">
      <el-tabs type="border-card" v-model="tabIndex" @tab-click="tabclick">
        <el-tab-pane label="员工排名"></el-tab-pane>
        <el-tab-pane label="门店排名"></el-tab-pane>
        <el-table
          border
          :data="tableData"
          size="small"
          style="width: 100%;"
          :height="tableHeight"
          :row-class-name="tableRowClassName">
          <el-table-column
            v-for="(col, i) in initcolumn"
            :key="i"
            :fixed="col.fixed || false"
            :prop="col.prop"
            :label="col.label"
            :width="col.width">
            <template slot-scope="scope">
              <el-image 
                v-if="col.prop == 'headUrl'" 
                style="width: 60px; height: 60px"
                :src="scope.row.headUrl" 
                :preview-src-list="[scope.row.headUrl]">
              </el-image>
              <span v-else>
                {{  scope.row[col.key] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'dingdanList',
  props: {
  },
  components: {
  },
  data() {
    return {
      tabIndex: 0,
      dateTime: [
        moment().startOf('month').format('yyyy-MM-DD'),
        moment().endOf('month').format('yyyy-MM-DD')
      ],
      searchTxt: '',
      searchTxtType: 'masterName',
      searchParams: {
        masterName: '',
        merchantOrderFlag: false,
        merchantOrder: ''
      },
      infoList: [],
      initInfoList: [],
      openFlag: false,
      tableData: [],
      initcolumn:[],
      column: [{
        label: '员工名称',
        prop: 'memberNick',
        key: 'memberNick',
      },{
        label: '总转发数',
        prop: 'shareNum',
        key: 'shareNum',
      },{
        label: '成交额',
        prop: 'gainMoney',
        key: 'gainMoney'
      },{
        label: '成交数',
        prop: 'gainNum',
        key: 'gainNum'
      },{
        label: '总收益',
        prop: 'paper',
        key: 'paper'
      }],
      column2: [{
        label: '门店名称',
        prop: 'shopName',
        key: 'shopName'
      },{
        label: '总转发数',
        prop: 'shareNum',
        key: 'shareNum'
      },{
        label: '成交客',
        prop: 'dealGuest',
        key: 'dealGuest'
      },{
        label: '成交数',
        prop: 'gainNum',
        key: 'gainNum'
      },{
        label: '成交额',
        prop: 'gainMoney',
        key: 'gainMoney'
      }],
      params: {
        activityCode: '',
        masterCode: '',
        statrCreateDate: '',
        endCreateDate: ''
      },
      totalCount: 0,
      tableHeight: 400,
      drawerTitle: '新增',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {}, //商家提现对象
      showPZflag: false, //批注弹窗显示
      pzContent: '',
      showQRcodeflag: false, //员工二维码弹窗
      showRecord: false, //员工记录弹窗
      attachment: {},
      listLoading: true,
      orderStatusMap: {
        //订单状态(1：待支付，2：待核销，3：已核销,4:已取消,5:退款中,6:已退款)
        1: '待支付',
        2: '待核销',
        3: '已核销',
        4: '已取消',
        5: '退款中',
        6: '已退款'
      },
      isOpenGroupMap: {
        //购买类型 1开团 0参团 3抢购
        0: '参团',
        1: '开团',
        3: '抢购'
      },
      showBack: false,
      backReason: '',
      currentRow: null
    };
  },
  mounted() {
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    initData(row) {
      this.initcolumn = [...this.column]
      this.params.masterCode = row.masterCode
      this.params.activityCode = row.activityCode
      this.setTableHeight()
      this.searchList()
    },
    searchList() {
      this.getData()
    },
    getData() {
      this.dateTime = this.dateTime || []
      this.params.statrCreateDate = this.dateTime[0] || ''
      this.params.endCreateDate = this.dateTime[1] || ''
      this.listLoading = true
      let queryForm = {...this.params}
      let url = ''
      if (this.tabIndex == 0) {
        url = '/back/shareInfo/staffRankingList'
      }
      if (this.tabIndex == 1) {
        url = '/back/shareInfo/shopRankingList'
      }
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.data || []
        }
      }).catch(()=>{ this.listLoading = false })
    },
    tabclick(e) {
      this.tabIndex = e.index
      if (e.index == 0) {
        this.initcolumn = [...this.column]
      }
      if (e.index == 1) {
        this.initcolumn = [...this.column2]
      }
      this.searchList()
    },
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    },
  },
  watch: {
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 100px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
</style>