import Vue from 'vue'
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
// 引入需要跳转的页面
import login from "@/components/loginPage" 
import layout from "@/components/layoutPage.vue" 
import shangjiaList from "@/components/shangjia/shangjiaList" 
import mendianList from "@/components/mendian/mendianList" 
import yuangongList from "@/components/yuangong/yuangongList" 
import huodongList from "@/components/huodong/huodongList" 
import chanpinList from "@/components/chanpin/chanpinList" 
import dingdanList from "@/components/dingdan/dingdanList" 
import tixianList from "@/components/tixian/tixianList"
import huiyuanList from "@/components/huiyuan/huiyuanList"
import guijiList from "@/components/guiji/guijiList"
import jubaoList from "@/components/jubao/jubaoList"
import setting from "@/components/setting/settingIndex"
// 路由配置
var router = new Router({
  // 在routes里配置跳转路径已经对应的页面
  routes: [
    { path: '/login', component: login, name: '登录' },
    { path: '/home', component: layout, name: '主页',
    children: [
      { path: '/shangjia', component: shangjiaList, name: '商家' }, // 商家列表
      { path: '/mendian', component: mendianList, name: '门店' }, // 门店列表
      { path: '/yuangong', component: yuangongList, name: '员工' }, // 员工列表
      { path: '/chanpin', component: chanpinList, name: '产品' }, // 产品列表
      { path: '/huodong', component: huodongList, name: '活动' }, // 活动
      { path: '/dingdan', component: dingdanList, name: '订单' }, // 订单列表
      { path: '/tixian', component: tixianList, name: '提现' }, // 提现列表
      { path: '/huiyuan', component: huiyuanList, name: '会员' }, // 会员列表
      { path: '/guiji', component: guijiList, name: '轨迹' }, // 轨迹
      { path: '/jubao', component: jubaoList, name: '举报' }, // 举报
      { path: '/setting', component: setting, name: '系统配置' } // 设置
    ]
  }]
})
// 全局前置守卫
router.beforeEach((to, from, next) => {
  window.localStorage.getItem('userInfo')
  let userInfo = {}
  if (window.localStorage.getItem('userInfo') != '') {
    userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  }
  if (userInfo && userInfo.usertype == 2) {
    if (to.path == '/') {
      next('/dingdan')
    }
  } else if (to.path == '/') {
      next('/shangjia')
  }
  let token = window.localStorage.getItem('token')
  if (!token && to.path != '/login') {
    // 无token需要跳到登录页
    next('/login')
  }
  next();
});
export default router