<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
      <el-form-item label="商家" prop="masterCode">
        <el-select v-model="ruleForm.masterCode" placeholder="请选择" style="width:100%" :disabled="editFlag" @change="selectChange" clearable>
          <el-option v-for="(item, i) in  selectlist"
          :key="i" :label="item.masterName" :value="item.masterCode"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店名称" prop="shopName">
        <el-input v-model="ruleForm.shopName" placeholder="请填写门店名称"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contacts">
        <el-input v-model="ruleForm.contacts" placeholder="请填写联系人"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phoneNumber">
        <el-input v-model="ruleForm.phoneNumber" placeholder="请填写联系电话"></el-input>
      </el-form-item>
      <el-form-item label="门店地址" prop="address">
        <el-input placeholder="请选择地址" v-model="ruleForm.address">
          <template slot="append">
            <div @click="openMap"><i class="el-icon-location" style="font-size: 24px"></i></div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="shopPassword">
        <el-input v-model="ruleForm.shopPassword2" placeholder="不填写则不修改"></el-input>
        <p style="color: #888">默认密码yjk888</p>
      </el-form-item>
    </el-form>
    <el-dialog title="选择位置" :visible.sync="showMap" width="800px" append-to-body>
      <div class="map-wrap">
        <div style="width: 100%;height: 450px">
          <mapPage ref="map" @selectMap="selectMap"></mapPage>
        </div>
        <div class="mapBtn-group">
          <el-button @click="mapCancel">取 消</el-button>
          <el-button type="primary" @click="mapChoose">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import mapPage from '../chooseMap/map.vue'
export default {
  name: 'mendianCreate',
  components: {
    mapPage
  },
  props: {
  },
  data() {
    return {
      ruleForm: {
      },
      initForm: {
        address: '',
        contacts: '',
        latitude: '',
        longitude: '',
        masterCode: '',
        masterName: '',
        phoneNumber: '',
        shopPassword: '',
        shopPassword2: '',
        shopName: ''
      },
      rules: {
        shopName: [
          { required: true, message: '请输入门店名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        phoneNumber: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请选择地址', trigger: 'blur' }
        ],
        shopPassword: [
          { required: false, message: '请输入密码', trigger: 'blur' }
        ]
      },
      editFlag: false,
      selectlist: [], // 商家选择列表
      showMap: false
    };
  },
  methods: {
    getMasterList() {
      // 获取商家列表
      let url = '/back/masteruser/selectlist'
      this.$axios.post(`${url}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.selectlist = data.rows || []
        }
      })
    },
    getData(data) {
      this.editFlag = false
      this.getMasterList()
      if (data) {
        // 表示编辑
        this.editFlag = true
        // this.ruleForm = {...this.ruleForm, ...data}
        this.getshopInfo(data.shopCode)
      } else {
        this.ruleForm = this.initForm
      }
    },
    getshopInfo(shopCode) {
      // 获取店铺信息
      this.$axios.post(`/back/master/shop/info?shopCode=${shopCode}`).then((res)=> {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data || this.initForm
          this.ruleForm.shopPassword2 = ''
        }
      })
    },
    selectChange(e) {
      let masterName = this.selectlist.find((d) => {return d.masterCode == e}).masterName
      this.ruleForm.masterName = masterName
      console.log(e, masterName)
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveData()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveData() {
      let url = '/back/master/shop/save'
      if (this.ruleForm.shopPassword) {
        this.ruleForm.shopPassword2 = this.ruleForm.shopPassword
      }
      let dataForm = {
        address: this.ruleForm.address,
        contacts: this.ruleForm.contacts,
        latitude: this.ruleForm.latitude,
        longitude: this.ruleForm.longitude,
        masterCode: this.ruleForm.masterCode,
        masterName: this.ruleForm.masterName,
        phoneNumber: this.ruleForm.phoneNumber,
        shopPassword: this.ruleForm.shopPassword,
        shopName: this.ruleForm.shopName
      }
      if (this.editFlag == true) {
        url = '/back/master/shop/edit'
        dataForm.shopCode = this.ruleForm.shopCode
      }
      let query = this.$querystring.stringify(dataForm)
      this.$axios.post(`${url}?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.$emit('searchList')
          this.$emit('cancelForm')
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.clearValidate();
    },
    mapCancel() {
      this.showMap = false
    },
    mapChoose() {
      this.$refs.map.selectComplate()
    },
    openMap() {
      this.showMap = true
      setTimeout(() => {
        let pos = {
          latitude: this.ruleForm.latitude,
          longitude: this.ruleForm.longitude,
          address: this.ruleForm.address
        }
        this.$refs.map.initMap(pos)
      }, 0)
    },
    selectMap(pos) {
      this.ruleForm = {...this.ruleForm, ...pos}
      console.log(this.ruleForm)
      this.mapCancel()
    }
  }
}
</script>
<style>
.map-wrap {
  position: relative;
  height: 560px;
}
.mapBtn-group {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}
</style>