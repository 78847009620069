<template>
  <div class="commonListPage">
    <div class="list-opt" style="justify-content: flex-end;">
      <div style="display: flex;align-items: center;flex-wrap:wrap">
        <div style="width: 300px;margin-right: 10px">
          <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select">
            <el-select v-model="searchTxtType" slot="prepend" placeholder="请选择" clearable>
              <el-option label="会员名称" value="memberNick"></el-option>
              <el-option label="会员电话" value="tel"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="info-list">
    </div>
    <div ref="tableWrap" style="height: calc(100% - 156px)" v-loading="listLoading">
      <el-table
        border
        :data="tableData"
        size="small"
        style="width: 100%;"
        :height="tableHeight"
        :row-class-name="tableRowClassName">
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :fixed="col.fixed || false"
          :prop="col.prop"
          :label="col.label"
          :width="col.width">
          <template slot-scope="scope">
            <span v-if="col.key == 'status'">
              {{ statusMap[scope.row.status] }}
            </span>
            <span v-else-if="col.key == 'memberNick'">
              {{ scope.row.memberNick }}({{ scope.row.memberCode }})
            </span>
            <span v-else>
              {{  scope.row[col.key] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'dingdanList',
  props: {
  },
  components: {
  },
  data() {
    return {
      dateTime: '',
      searchTxt: '',
      searchTxtType: 'memberNick',
      searchParams: {
        merchantName: '',
        merchantOrderFlag: false,
        merchantOrder: ''
      },
      openFlag: false,
      tableData: [],
      column: [{
        label: '举报人',
        prop: 'memberNick',
        key: 'memberNick'
      },{
        label: '被举报商家',
        prop: 'masterName',
        key: 'masterName'
      },{
        label: '举报时间',
        prop: 'createTime',
        key: 'createTime'
      },{
        label: '状态',
        prop: 'status',
        key: 'status'
      },{
        label: '举报原因',
        prop: 'affair',
        key: 'affair'
      }],
      params: {
        currPage: 1,
        pageSize: 10
      },
      totalCount: 0,
      tableHeight: 400,
      drawerTitle: '新增',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {}, //商家提现对象
      showPZflag: false, //批注弹窗显示
      pzContent: '',
      checkRes: 1,
      showQRcodeflag: false, //员工二维码弹窗
      showRecord: false, //员工记录弹窗
      listLoading: true,
      statusMap: {
        //状态1:新创建.2:处理中，3:已处理
        1: '新建',
        2: '处理中',
        3: '已处理'
      }
    };
  },
  mounted() {
    this.setTableHeight()
    this.searchList()
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    handleResize() {
      console.log('窗口变')
      this.setTableHeight()
    },
    searchList() {
      delete this.params.memberNick
      delete this.params.tel
      this.params.currPage = 1
      this.params.pageSize = 10
      this.getData()
    },
    getData() {
      this.params[this.searchTxtType] = this.searchTxt
      this.listLoading = true
      let url = '/back/vipcomplain/list'
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.list || []
          this.totalCount = data.totalCount
        }
      }).catch(()=>{ this.listLoading = false })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(page) {
      this.params.currPage = page
      this.getData()
    },
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    },
    create(type, row) {
      this.drawerShow = true
      if (type == 0) {
        this.drawerTitle = '新增'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData()
        }, 0)
      }
      if (type == 1) {
        this.drawerTitle = '编辑产品'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData(row)
        }, 0)
      }
    },
    openFxr(row) {
      //  打开商家资料
      this.showPZflag = true
      this.pzContent = ''
      this.checkRes = 1
      console.log(row)
    },
    cancelFileFrom() {
      this.$refs.fileFrom.resetForm()
      this.fileShow = false
    },
    submitFileForm() {
      this.$refs.fileFrom.submitForm()
    },
    submitForm() {
      this.$refs.forms.submitForm()
    },
    cancelForm() {
      this.$refs.forms.resetForm()
      this.drawerShow = false
    },
    showTX() {
      this.showTXflag = true
    },
    submitTX() {
      this.showTXflag = false
    },
    submitPZ() {
      this.showPZflag = false
    },
    BDswitch(row) {
      if (row.bindingFlag == 0) {
        // 需要绑定
        this.$confirm('是否需要绑定该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 1
        }).catch(() => {         
        });
      }
      if (row.bindingFlag == 1) {
        // 需要解绑
        this.$confirm('是否需要解绑该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 0
        }).catch(() => {         
        });
      }
    },
    showQRcode(row) {
      console.log(row)
      this.showQRcodeflag = true
    },
    backRow(row) {
      this.$confirm('是否撤回该订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
      }).catch(() => {         
      });
    },
    openRecord() {
      this.showRecord = true
    }
  },
  watch: {
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 120px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
</style>