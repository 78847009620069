<template>
  <div class="commonListPage">
    <div class="list-opt" style="justify-content: flex-end;">
      <div style="display: flex;align-items: center;flex-wrap:wrap">
        <div style="margin-right: 10px">
          <span style="font-size: 14px;margin-right: 10px">门店</span>
          <el-select v-model="params.shopCode" placeholder="请选择" style="width: 120px" clearable>
            <el-option v-for="(item, i) in shopList" :key="i" :label="item.shopName" :value="item.shopCode" ></el-option>
          </el-select>
        </div>
        <div style="width: 300px;margin-right: 10px">
          <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select">
            <el-select v-model="searchTxtType" slot="prepend" placeholder="请选择" clearable>
              <el-option label="商家名称" value="masterName"></el-option>
              <el-option label="门店名称" value="shopName"></el-option>
              <el-option label="活动名称" value="activityName"></el-option>
              <el-option label="会员名称" value="memberNick"></el-option>
              <el-option label="会员电话" value="tel"></el-option>
              <el-option label="推广人名称" value="fMemberNick"></el-option>
              <el-option label="推广人电话" value="fTel"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="info-list">
    </div>
    <div ref="tableWrap" style="height: calc(100% - 156px)" v-loading="listLoading">
      <el-table
        border
        :data="tableData"
        size="small"
        style="width: 100%;"
        :height="tableHeight"
        :row-class-name="tableRowClassName">
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :fixed="col.fixed || false"
          :prop="col.prop"
          :label="col.label"
          :width="col.width">
          <template slot-scope="scope">
            <template v-if="col.prop == 'member'">
              <div style="display:flex;align-items: center;">
                <el-image 
                  style="width: 60px; height: 60px;margin-right: 10px"
                  :src="scope.row.headUrl" 
                  :preview-src-list="[scope.row.headUrl]">
                </el-image>
                <span>{{scope.row.memberNick}}({{ scope.row.memberCode }})</span>
              </div>
            </template>
            <template v-else-if="col.prop == 'promoter'">
              <div style="display:flex;align-items: center;">
                <el-image 
                  style="width: 60px; height: 60px;margin-right: 10px"
                  :src="scope.row.fromMemberHeadUrl" 
                  :preview-src-list="[scope.row.fromMemberHeadUrl]">
                </el-image>
                <span>{{scope.row.fromMemberNick}}({{ scope.row.fromMemberCode }})</span>
              </div>
            </template>
            <template v-else-if="col.prop == 'activityName'">
              <span>{{scope.row.activityName}}({{ scope.row.activityCode }})</span>
            </template>
            <span v-else>
              {{  scope.row[col.key] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'dingdanList',
  props: {
  },
  components: {
  },
  data() {
    return {
      dateTime: '',
      searchTxt: '',
      searchTxtType: 'memberNick',
      searchParams: {
        merchantName: '',
        merchantOrderFlag: false,
        merchantOrder: ''
      },
      infoList: [],
      initInfoList: [],
      openFlag: false,
      tableData: [],
      column: [{
        label: '商家名称',
        prop: 'masterName',
        key: 'masterName',
        minWidth: 150
      },
      // {
      //   label: '门店名称',
      //   prop: 'shopName',
      //   key: 'shopName',
      //   minWidth: 150
      // },
      {
        label: '活动名称',
        prop: 'activityName',
        key: 'activityName',
        minWidth: 180
      },{
        label: '会员',
        prop: 'member',
        key: 'member',
        width: 220
      },{
        label: '手机号码',
        prop: 'tel',
        key: 'tel',
      },{
        label: '推广人',
        prop: 'promoter',
        key: 'promoter',
        width: 220
      },{
        label: '推广人手机',
        prop: 'fromMemberTel',
        key: 'fromMemberTel'
      },{
        label: '邀请时间',
        prop: 'createtime',
        key: 'createtime'
      }],
      params: {
        currPage: 1,
        pageSize: 10
      },
      totalCount: 0,
      tableHeight: 400,
      drawerTitle: '新增',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {}, //商家提现对象
      showPZflag: false, //批注弹窗显示
      pzContent: '',
      checkRes: 1,
      showQRcodeflag: false, //员工二维码弹窗
      showRecord: false, //员工记录弹窗
      shopList: [], //门店列表
      listLoading: true
    };
  },
  mounted() {
    this.getShopList()
    this.setTableHeight()
    this.searchList()
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    handleResize() {
      console.log('窗口变')
      this.setTableHeight()
    },
    getShopList() {
      // 获取门店列表
      let url = '/back/master/shop/list'
      let query = this.$querystring.stringify({
        pageSize: 100000
      })
      this.$axios.post(`${url}?${query}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.shopList = data.list || []
        }
      })
    },
    searchList() {
      delete this.params.masterName
      delete this.params.shopName
      delete this.params.activityName
      delete this.params.memberNick
      delete this.params.tel
      delete this.params.fMemberNick
      delete this.params.fTel
      this.params.currPage = 1
      this.params.pageSize = 10
      this.getData()
    },
    getData() {
      this.params[this.searchTxtType] = this.searchTxt
      this.listLoading = true
      let url = '/back/invite/list'
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.list || []
          this.totalCount = data.totalCount
          this.attachment = data.attachment || {}
        }
      }).catch(()=>{ this.listLoading = false })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(page) {
      this.params.currPage = page
      this.getData()
    },
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    }
  },
  watch: {
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 120px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
</style>