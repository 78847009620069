<template>
  <div class="commonListPage">
    <div class="list-opt">
      <el-button type="primary" icon="el-icon-plus" size="medium" plain @click="create(0)">新增商家</el-button>
      <div>
        <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select">
          <el-select v-model="searchTxtType" slot="prepend" placeholder="请选择" clearable>
            <el-option label="商家名称" value="masterName"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
        </el-input>
      </div>
    </div>
    <div class="info-list">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="8" :md="4" :lg="4" :xl="4" v-for="(item, i) in initInfoList" :key="i">
          <div class="info-content">
            <span>{{item.name}}</span>
            <span>{{attachment[item.key]}}</span>
          </div>
        </el-col>
      </el-row>
      <div class="info-list-action" @click="()=>{openFlag = !openFlag}">
        <span>{{ openFlag ? '收起' : '展开'}}</span>
        <i :class="openFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </div>
    </div>
    <div ref="tableWrap" style="height: calc(100% - 156px)" v-loading="listLoading">
      <el-table
        :data="tableData"
        border
        size="small"
        style="width: 100%;"
        :height="tableHeight"
        :row-class-name="tableRowClassName">
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :fixed="col.fixed || false"
          :prop="col.prop"
          :label="col.label"
          :width="col.width">
          <template slot-scope="scope">
            <template v-if="col.prop == 'logo' && scope.row.logo">
              <el-image
                style="width: 60px; height: 60px"
                :src="JSON.parse(scope.row.logo).logo" 
                :preview-src-list="[JSON.parse(scope.row.logo).logo]">
              </el-image>
            </template>
            <template v-else-if="col.prop == 'regTime'">
              <p>入驻时间：{{scope.row.regTime}}</p>
              <p>过期时间：{{scope.row.expireDate}}</p>
            </template>
            <template v-else-if="col.prop == 'isenable'">
              <el-switch
                v-model="scope.row.isenable"
                :active-value="1"
                :inactive-value="0"
                @change="upDateisenable($event, scope.row)">
              </el-switch>
            </template>
            <template v-else-if="col.prop == 'isShopwithdraw'">
              <el-switch
                v-model="scope.row.isShopwithdraw"
                active-value="1"
                inactive-value="0"
                @change="upDateisShopwithdraw($event, scope.row)">
              </el-switch>
            </template>
            <div class="option-box" v-else-if="col.prop == 'action'">
              <el-button type="primary" icon="el-icon-tickets" plain size="small" @click="openSJFile(scope.row)"></el-button>
              <el-button type="primary" icon="el-icon-setting" plain size="small" @click="showTX(scope.row)"></el-button>
              <el-button type="primary" icon="el-icon-edit" plain size="small" @click="showPZ(scope.row)"></el-button>
              <el-button type="primary" icon="el-icon-edit-outline" plain size="small" @click="create(1, scope.row)"></el-button>   
              <el-button type="primary" icon="el-icon-s-grid" plain size="small" @click="showQRcode(scope.row)"></el-button>
              <el-button type="primary" icon="el-icon-connection" plain size="small" @click="BDswitch(scope.row)"></el-button>
              <el-button type="primary" icon="el-icon-delete" plain size="small" @click="deleteRow(scope.row)"></el-button>
            </div>
            <span v-else>
              {{  scope.row[col.key] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawerShow">
      <div class="drawer-content">
        <div class="drawer-content-main">
          <createPage ref="forms" @cancelForm="cancelForm" @searchList="searchList"></createPage>
        </div>
        <div class="drawer-bottom">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="submitForm" :loading="submitloading">{{ submitloading ? '提交中 ...' : '确定' }}</el-button>
        </div>
      </div>
    </el-drawer>
    <el-drawer
    size="60%"
    :title="'商家资料'"
    :visible.sync="fileShow">
    <div class="drawer-content">
      <div class="drawer-content-main">
        <SJFile ref="fileFrom" @cancelForm="cancelFileFrom" @searchList="searchList"></SJFile>
      </div>
      <div class="drawer-bottom">
        <el-button @click="cancelFileFrom">取 消</el-button>
        <el-button type="primary" @click="submitFileForm" :loading="submitloading">{{ submitloading ? '提交中 ...' : '确定' }}</el-button>
      </div>
    </div>
  </el-drawer>
    <el-dialog title="修改商家提现费率" :visible.sync="showTXflag" width="500px">
      <el-form :model="txData" label-position="left" label-width="100px">
        <el-form-item label="提现费率">
          <el-input v-model="txData.serviceMoneyProportion">
            <span slot="suffix" style="padding-right: 10px">%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="开发服务费">
          <el-input v-model="txData.exploitMoney">
            <span slot="suffix" style="padding-right: 10px">元</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTXflag = false">取 消</el-button>
        <el-button type="primary" @click="submitTX">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="备注" :visible.sync="showPZflag" width="600px">
      <el-input type="textarea" v-model="pzContent" placeholder="请填写备注" rows="5"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPZflag = false">取 消</el-button>
        <el-button type="primary" @click="submitPZ">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="员工注册二维码" :visible.sync="showQRcodeflag" width="300px">
      <div style="text-align: center">
        <el-image 
          style="width: 200px; height: 200px"
          :src="qrCode">
        </el-image>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import createPage from './create.vue'
import SJFile from './file.vue'
export default {
  name: 'shangjiaList',
  props: {
  },
  components: {
    createPage,
    SJFile
  },
  data() {
    return {
      searchTxt: '',
      searchTxtType: 'masterName',
      infoList: [{
        name: '总金额',
        key: 'sumbalance',
        val: ''
      },{
        name: '总提现金额',
        key: 'sumwithdrawbalance',
        val: ''
      },{
        name: '总提现中金额',
        key: 'sumwithdrawfrozenbalance',
        val: ''
      },{
        name: '总可提现金额',
        key: 'sumtotalmoney',
        val: ''
      },{
        name: '总商家实收',
        key: 'summastergainmoney',
        val: ''
      },{
        name: '总分享者收入',
        key: 'sumsharergainmoney',
        val: ''
      },{
        name: '交易手续费',
        key: 'sumplatgainmoney',
        val: ''
      }],
      initInfoList: [],
      attachment: {},
      openFlag: false,
      tableData: [],
      currentRow: {},
      column: [{
        label: 'logo',
        prop: 'logo',
        key: 'logo',
        width: 120,
      },{
        label: '商户名称',
        prop: 'masterName',
        key: 'masterName',
        width: 200,
      },{
        label: '商户账号',
        prop: 'adminId',
        key: 'adminId',
        width: 120,
      },{
        label: '联系电话',
        prop: 'tel',
        key: 'tel',
        width: 120,
      },{
        label: '商户活动总数',
        prop: 'activityTotal',
        key: 'activityTotal',
        width: 150,
      },
      // {
      //   label: '开发服务费',
      //   prop: 'exploitMoney',
      //   key: 'exploitMoney',
      //   width: 120,
      // },
      {
        label: '进行中活动数量',
        prop: 'activityStartNum',
        key: 'activityStartNum',
        width: 150,
      },{
        label: '入驻时间',
        prop: 'regTime',
        key: 'regTime',
        width: 200,
      },{
        label: '总营收',
        prop: 'totalRevenueBalance',
        key: 'totalRevenueBalance',
        width: 150,
      },{
        label: '总提现',
        prop: 'totalWithdrawBalance',
        key: 'totalWithdrawBalance',
        width: 150,
      },{
        label: '提现中',
        prop: 'withdrawFrozenBalance',
        key: 'withdrawFrozenBalance',
        width: 150,
      },{
        label: '可提现',
        prop: 'balance',
        key: 'balance',
        width: 150,
      },{
        label: '待入账',
        prop: 'rewardAmount',
        key: 'rewardAmount',
        width: 150,
      },{
        label: '提现费率(%)',
        prop: 'serviceMoneyProportion',
        key: 'serviceMoneyProportion',
        width: 150,
      },{
        label: '交易手续费',
        prop: 'platGainMoney',
        key: 'platGainMoney',
        width: 150,
      },{
        label: '商家实收',
        prop: 'masterGainMoney',
        key: 'masterGainMoney',
        width: 150,
      },{
        label: '分享者收入',
        prop: 'sharerGainMoney',
        key: 'sharerGainMoney',
        width: 150,
      },{
        label: '商家上下架',//是否启用1启用0禁用
        prop: 'isenable',
        key: 'isenable',
        width: 150,
      },{
        label: '是否提现门店',
        prop: 'isShopwithdraw',
        key: 'isShopwithdraw',
        width: 150,
      },{
        label: '操作',
        prop: 'action',
        key: 'action',
        width: 250,
        fixed: 'right'
      }],
      listLoading: true,
      params: {
        currPage: 1,
        pageSize: 10
      },
      totalCount: 1,
      totalPage: 1,
      tableHeight: 400,
      drawerTitle: '新增商家',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {
        serviceMoneyProportion: '',
        exploitMoney: ''
      }, //商家提现对象
      showPZflag: false, //批注弹窗显示
      pzContent: '',
      showQRcodeflag: false, //员工二维码弹窗
      fileShow: false, //商家资料弹窗
      qrCode: ''
    };
  },
  mounted() {
    this.setTableHeight()
    this.searchList()
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    handleResize() {
      console.log('窗口变')
      this.setTableHeight()
    },
    searchList() {
      this.params.currPage = 1
      this.params.pageSize = 10
      this.getData()
    },
    getData() {
      this.params[this.searchTxtType] = this.searchTxt
      this.listLoading = true
      let url = '/back/masteruser/list'
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.list
          this.totalPage = data.totalPage
          this.totalCount = data.totalCount
          this.attachment = data.attachment || {}
        }
      }).catch(()=>{ this.listLoading = false })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(page) {
      this.params.currPage = page
      this.getData()
    },
    setTableHeight() {
      this.$nextTick(() => {
        if (this.smFlag == 1) {
          console.log('小小小')
          this.column[this.column.length -1].fixed = false
        }
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    },
    create(type, row) {
      this.drawerShow = true
      if (type == 0) {
        this.drawerTitle = '新增商家'
        setTimeout(() => {
          this.$refs.forms.resetForm()
        }, 0)
        setTimeout(() => {
          this.$refs.forms.getData()
        }, 1)
      }
      if (type == 1) {
        this.drawerTitle = '编辑商家'
        setTimeout(() => {
          this.$refs.forms.resetForm()
        }, 0)
        setTimeout(() => {
          this.$refs.forms.getData(row)
        }, 1)
      }
    },
    openSJFile(row) {
      //  打开商家资料
      this.fileShow = true
      setTimeout(() => {
        this.$refs.fileFrom.resetForm()
        this.$refs.fileFrom.getData(row)
      }, 0)
    },
    cancelFileFrom() {
      this.$refs.fileFrom.resetForm()
      this.fileShow = false
    },
    submitFileForm() {
      this.$refs.fileFrom.submitForm()
    },
    submitForm() {
      this.$refs.forms.submitForm()
    },
    cancelForm() {
      this.$refs.forms.resetForm()
      this.drawerShow = false
    },
    showTX(row) {
      this.currentRow = JSON.parse(JSON.stringify(row))
      this.txData.exploitMoney = row.exploitMoney
      this.txData.serviceMoneyProportion = row.serviceMoneyProportion
      this.showTXflag = true
    },
    submitTX() {
      let row = this.currentRow
      let url = '/back/masteruser/modifyServiceMoneyProportion'
      let query = this.$querystring.stringify({
        masterCode: row.masterCode,
        exploitMoney: this.txData.exploitMoney,
        serviceMoneyProportion: this.txData.serviceMoneyProportion
      })
      this.$axios.post(`${url}?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.showPZflag = false
          this.getData()
        }
      })
      this.showTXflag = false
    },
    showPZ(row) {
      this.currentRow = JSON.parse(JSON.stringify(row))
      this.pzContent = this.currentRow.remarks || ''
      this.showPZflag = true
    },
    submitPZ() {
      let row = this.currentRow
      let query = this.$querystring.stringify({
        masterCode: row.masterCode,
        remarks: this.pzContent
      })
      this.$axios.post(`/back/masteruser/modifyRemarks?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.showPZflag = false
          this.getData()
        }
      })
    },
    BDswitch(row) {
      // if (row.bindingFlag == 0) {
      //   // 需要绑定
      //   this.$confirm('是否需要绑定该商家?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     row.bindingFlag = 1
      //   }).catch(() => {         
      //   });
      // }
      // if (row.bindingFlag == 1) {
      //   // 需要解绑
      //   this.$confirm('是否需要解绑该商家?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     row.bindingFlag = 0
      //   }).catch(() => {         
      //   });
      // }
      this.$confirm('是否需要解绑该商家?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.unboundOpenId(row)
      }).catch(() => {         
      });
    },
    unboundOpenId(row) {
      this.$axios.post(`/back/masteruser/unboundOpenId?masterCode=${row.masterCode}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getData()
        }
      })
    },
    showQRcode(row) {
      console.log(row)
      this.currentRow = JSON.parse(JSON.stringify(row))
      let query = {
        appId: 'wxc21ffa456349da82',
        page: 'pages/memberReg',
        scene: row.masterCode,
        width: 400
      }
      this.$axios.post(`/back/createWxQrCode`, query).then((res)=> {
        if (res.data.code == 200) {
          this.showQRcodeflag = true
          this.qrCode = res.data.data
        }
      })
    },
    deleteRow(row) {
      this.$confirm('是否删除该商家?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteReq(row)
      }).catch(() => {         
      });
    },
    deleteReq(row) {
      this.$axios.post(`/back/masteruser/delete?masterCode=${row.masterCode}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getData()
        }
      })
    },
    upDateisenable(e, row) {
      let query = this.$querystring.stringify({
        masterCode: row.masterCode,
        isenable: e
      })
      this.$axios.post(`/back/masteruser/modifyRemarks?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success('操作成功')
        } else {
          // 没有成功，把状态还原
          row.isenable = Number(row.isenable) === 0 ? 1 : 0
        }
      })
    },
    upDateisShopwithdraw(e, row) {
      let query = this.$querystring.stringify({
        masterCode: row.masterCode,
        isShopwithdraw: e
      })
      this.$axios.post(`/back/masteruser/checkIsShopwithdraw?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
        } else {
          // 没有成功，把状态还原
          row.isShopwithdraw = Number(row.isShopwithdraw) === 0 ? '1' : '0'
        }
      })
    }
  },
  watch: {
    openFlag: {
      handler: function (val) {
        if (val) {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList))
        } else {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList.slice(0, 6)))
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 120px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
.option-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.option-box button{
  margin-bottom: 5px;
}
</style>