<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
      <el-form-item label="商家名称" prop="masterName">
        <el-input v-model="ruleForm.masterName" placeholder="请填写商家名称"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input v-model="ruleForm.tel" placeholder="请填写联系电话"></el-input>
      </el-form-item>
      <el-form-item label="商家地址" prop="detailInfo">
        <el-input type="textarea" v-model="ruleForm.detailInfo" placeholder="请填写商家地址"></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="adminPwd">
        <el-input v-model="ruleForm.adminPwd2" placeholder="不填写则不修改"></el-input>
        <p style="color: #888">默认密码yjk888</p>
      </el-form-item>
      <el-form-item label="商户有效期">
        <el-form-item prop="expireDate">
          <el-date-picker type="datetime" format="yyyy-MM-DD" value-format="yyyy-MM-DD" placeholder="不填写则默认无限期" v-model="ruleForm.expireDate" style="width: 100%;"></el-date-picker>
        </el-form-item>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'shangjiaCreate',
  props: {
  },
  data() {
    return {
      ruleForm: {
      },
      initForm: {
        masterName: '',
        tel: '',
        detailInfo: '',
        adminPwd: '',
        adminPwd2: '',
        expireDate: ''
      },
      rules: {
        masterName: [
          { required: true, message: '请输入商家名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        detailInfo: [
          { required: true, message: '请输入商家地址', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    getData(data) {
      if (data) {
        this.getDetails(data)
      } else {
        this.ruleForm = this.initForm
      }
    },  
    getDetails(row) {
      // 编辑获取数据详情
      this.$axios.post(`/back/masteruser/detailsShopList?masterCode=${row.masterCode}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.ruleForm = {...data.masterUser}
          this.ruleForm.adminPwd2 = ''
        }
      }).catch(()=>{})
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveData()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveData() {
      let url = '/back/masteruser/modifyMaster'
      if (this.ruleForm.adminPwd2) {
        this.ruleForm.adminPwd = this.ruleForm.adminPwd2
      }
      let dataForm = {
        masterName: this.ruleForm.masterName,
        contact: this.ruleForm.contact,
        tel: this.ruleForm.tel,
        detailInfo: this.ruleForm.detailInfo,
        expireDate: this.ruleForm.expireDate,
        adminPwd: this.ruleForm.adminPwd
      }
      if (this.ruleForm.masterCode) {
        dataForm.masterCode = this.ruleForm.masterCode
      }
      let query = this.$querystring.stringify(dataForm)
      this.$axios.post(`${url}?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.$emit('searchList')
          this.$emit('cancelForm')
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.clearValidate();
    }
  }
}
</script>