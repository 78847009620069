<template>
  <div class="commonListPage">
    <div class="list-opt">
      <el-button type="primary" icon="el-icon-plus" size="medium" plain @click="create(0)">新增产品</el-button>
      <div style="display: flex;align-items: center;flex-wrap:wrap">
        <!-- <el-date-picker
          style="margin-right: 10px"
          v-model="dateTime"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker> -->
        <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select">
          <el-select v-model="searchTxtType" slot="prepend" placeholder="请选择" clearable>
            <el-option label="商家名称" value="masterName"></el-option>
            <el-option label="商家编码" value="masterCode"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
        </el-input>
      </div>
    </div>
    <div class="info-list">
      <!-- <el-row :gutter="20">
        <el-col :span="4" v-for="(item, i) in initInfoList" :key="i">
          <div class="info-content">
            <span>{{item.name}}</span>
            <span>{{item.val}}</span>
          </div>
        </el-col>
      </el-row>
      <div class="info-list-action" @click="()=>{openFlag = !openFlag}">
        <span>{{ openFlag ? '收起' : '展开'}}</span>
        <i :class="openFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </div> -->
    </div>
    <div ref="tableWrap" style="height: calc(100% - 116px)" v-loading="listLoading">
      <el-table
        :data="tableData"
        border
        size="small"
        style="width: 100%;"
        :height="tableHeight"
        :row-class-name="tableRowClassName">
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :fixed="col.fixed || false"
          :prop="col.prop"
          :label="col.label"
          :width="col.width">
          <template slot-scope="scope">
            <template v-if="col.prop == 'action'">
              <el-button type="primary" icon="el-icon-edit-outline" plain size="small" @click="create(1, scope.row)"></el-button>
              <el-button type="primary" icon="el-icon-delete" plain size="small" @click="deleteRow(scope.row)"></el-button>
            </template>
            <span v-else>
              {{  scope.row[col.key] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawerShow"
      size="40%">
      <div class="drawer-content">
        <div class="drawer-content-main">
          <createPage ref="forms" @cancelForm="cancelForm" @searchList="searchList"></createPage>
        </div>
        <div class="drawer-bottom">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="submitForm" :loading="submitloading">{{ submitloading ? '提交中 ...' : '确定' }}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import createPage from './create.vue'
export default {
  name: 'shangjiaList',
  props: {
  },
  components: {
    createPage
  },
  data() {
    return {
      dateTime: '',
      searchTxt: '',
      searchTxtType: 'masterName',
      initInfoList: [],
      openFlag: false,
      tableData: [],
      column: [{
        label: '商户名称',
        prop: 'masterName',
        key: 'masterName'
      },{
        label: '产品名称',
        prop: 'productName',
        key: 'productName'
      },{
        label: '产品价格',
        prop: 'price',
        key: 'price'
      },{
        label: '操作',
        prop: 'action',
        key: 'action',
        width: 250,
      }],
      params: {
        currPage: 1,
        pageSize: 10
      },
      totalCount: 1,
      tableHeight: 400,
      drawerTitle: '新增',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {}, //商家提现对象
      showPZflag: false, //批注弹窗显示
      pzContent: '',
      showQRcodeflag: false, //员工二维码弹窗
      showRecord: false, //员工记录弹窗
      recordData: [
        {
          name: '李四',
          phone: 13813812138,
          storeName: '耐克直营万达店',
          changeStoreName: '耐克银泰店',
          changeTime: '2021-10-11 18:29:16',
        }
      ],
      recordDataCol: [
        {
          label: '员工名称',
          prop: 'name',
          key: 'name',
          width: 100
        },
        {
          label: '手机号',
          prop: 'phone',
          key: 'phone'
        },
        {
          label: '原门店',
          prop: 'storeName',
          key: 'storeName'
        },
        {
          label: '变更门店',
          prop: 'changeStoreName',
          key: 'changeStoreName'
        },
        {
          label: '变更时间',
          prop: 'changeTime',
          key: 'changeTime',
          width: 140
        }
      ],
      listLoading: true
    };
  },
  mounted() {
    this.setTableHeight()
    this.searchList()
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    handleResize() {
      console.log('窗口变')
      this.setTableHeight()
    },
    searchList() {
      this.params = {}
      this.params.currPage = 1
      this.params.pageSize = 10
      this.getData()
    },
    getData() {
      this.listLoading = true
      let url = '/back/product/list'
      this.params[this.searchTxtType] = this.searchTxt
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.rows || []
          this.totalCount = data.total
        }
      }).catch(()=>{ this.listLoading = false })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(page) {
      this.params.currPage = page
      this.getData()
    },
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    },
    create(type, row) {
      this.drawerShow = true
      if (type == 0) {
        this.drawerTitle = '新增'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData()
        }, 0)
      }
      if (type == 1) {
        this.drawerTitle = '编辑产品'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData(row)
        }, 0)
      }
    },
    openSJFile(row) {
      //  打开商家资料
      this.fileShow = true
      setTimeout(() => {
        this.$refs.fileFrom.resetForm()
        this.$refs.fileFrom.getData(row)
      }, 0)
    },
    cancelFileFrom() {
      this.$refs.fileFrom.resetForm()
      this.fileShow = false
    },
    submitFileForm() {
      this.$refs.fileFrom.submitForm()
    },
    submitForm() {
      this.$refs.forms.submitForm()
    },
    cancelForm() {
      this.submitloading = false
      this.$refs.forms.resetForm()
      this.drawerShow = false
    },
    showTX() {
      this.showTXflag = true
    },
    submitTX() {
      this.showTXflag = false
    },
    showPZ() {
      this.showPZflag = true
    },
    submitPZ() {
      this.showPZflag = false
    },
    BDswitch(row) {
      if (row.bindingFlag == 0) {
        // 需要绑定
        this.$confirm('是否需要绑定该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 1
        }).catch(() => {         
        });
      }
      if (row.bindingFlag == 1) {
        // 需要解绑
        this.$confirm('是否需要解绑该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 0
        }).catch(() => {         
        });
      }
    },
    showQRcode(row) {
      console.log(row)
      this.showQRcodeflag = true
    },
    deleteRow(row) {
      this.$confirm('是否删除该产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteReq(row)
      }).catch(() => {         
      });
    },
    deleteReq(row) {
      this.$axios.get(`/back/product/delete?productCode=${row.productCode}`).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getData()
        }
      })
    },
    openRecord() {
      this.showRecord = true
    }
  },
  watch: {
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 100px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
</style>